import React, { Suspense } from "react";
import LoadingPlx from "../LoadingPlx/LoadingPlx";

/**
 * @description 路由懒加载
 * @param {Element} Comp 需要访问的组件
 * @returns element
 */
const lazyLoad = (Comp: any): React.ReactNode => {
  return (
    <Suspense fallback={<LoadingPlx />}>
      <Comp />
    </Suspense>
  );
};

export default lazyLoad;