import { BaseAPI } from "./base/baseApi";
import { AddDroneParams, DeleteDroneParams, HandoffRouteParams, QuerAwaitHandoffRouteParams, QuerNowFlightRouteParams, QueryDroneParams, QueryFlightPathListParams, QueryFlightPathPlaybackParams, UpdateDroneParams, queryAirportInfoParams } from "./interface"
export class DroneAPI extends BaseAPI {
  // 新增无人机
  addDrone(data: AddDroneParams) {
    return this.post("/aircraft", data)
  }
  // 查询无人机
  queryDrone(data: QueryDroneParams) {
    return this.get("/aircraft", data)
  }
  // 更新无人机
  updateDrone(data: UpdateDroneParams) {
    return this.put("/aircraft", data)
  }
  // 删除无人机
  deleteDrone(data: DeleteDroneParams) {
    return this.delete(`/aircraft?id=${data.id}`, data)
  }
  //查询航迹列表
  queryFlightPathList(data: QueryFlightPathListParams) {
    return this.get("/monitor/flight/data", data)
  }
  // 带切换航线列表
  querAwaitHandoffRoute(data: QuerAwaitHandoffRouteParams) {
    return this.get("/mission/route/switch", data)
  }
  // 查询航迹回放数据
  queryFlightPathPlayback(data: QueryFlightPathPlaybackParams) {
    return this.get("/monitor/flight/playback", data)
  }
  // 切换航线
  handoffRoute(data: HandoffRouteParams) {
    return this.post("/mission/route/switch", data)
  }
  //当前飞机航线信息
  querNowFlightRoute(data: QuerNowFlightRouteParams) {
    return this.get("/monitor/flying/route", data)
  }
  //当前飞机飞行信息
  queryNowFlight(data: queryAirportInfoParams) {
    return this.get("/monitor/flying/latest/data", data)
  }
  downLodePayBack(data: DeleteDroneParams) {
    return this.post("/monitor/download/flight/data", data, {
      responseType: "blob",
    })
  }
}
const droneAPI = new DroneAPI()
export default droneAPI