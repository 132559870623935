import { INotificationModule, IResponseBody } from "@/common/interface";
// import { upperCasePlx } from "@/common/utils";
import accountService from "@/store/account/service";
import axios, { AxiosRequestConfig } from "axios";
// import affairService from "./affairs";
import resolveReq from "./resolve";
import tool from "./tool";
// import Cookies from '@/js-cookie';

const httpConfig: AxiosRequestConfig = {
  timeout: 60000,
  withCredentials: true,
  baseURL: "https://test.zhufly.com/uavapi",
};
if (process.env.NODE_ENV === "development") {
  // httpConfig.baseURL = "http://127.0.0.1:8001/api";
}

const httpAxios = axios.create(httpConfig);

const log = tool.createDebug(true, "网络请求");

/**
 * 网络请求服务
 * @param config
 * @param needLogin
 */
const reqService = <T>(
  config: AxiosRequestConfig & INotificationModule,
  needLogin = true
) => {
  const tarConfig: any = { ...config };
  // 将操作成功提示从往后端的提示中删除
  delete tarConfig.__message;
  // const token = getTokenCookies();
  // const methodType = config.method && (upperCasePlx(config.method) as any);
  // if (needLogin && token) {
  //   tarConfig.headers["Authorization"] = token;
  // }
  // if (config.method && ["DELETE", "PUT", "POST"].includes(methodType)) {
  //   if (config.url) {
  //     // 如果有操作结果配置
  //     if (!!config.__message) {
  //       if (!config.__message.disable) {
  //         affairService.addEvent(
  //           config.url,
  //           config.method.toLowerCase(),
  //           config.__message.successInfo
  //         );
  //       }
  //     } else {
  //       affairService.addEvent(config.url, config.method.toLowerCase());
  //     }
  //   }
  // }

  return new Promise<T | null>((resolve, reject) => {
    httpAxios
      .request<IResponseBody<T>>(tarConfig)
      .then((res: any) => {
        if (tarConfig.responseType === "blob") {
          resolve(res);
          return;
        }

        if (res.status === 200) {
          const rep = resolveReq<T>(res);
          if (rep.success) {
            // if (
            //   config.method &&
            //   ["DELETE", "PUT", "POST"].includes(
            //     upperCasePlx(config.method) as any
            //   )
            // ) {
            //   // 如果有操作结果配置
            //   if (config.url) {
            //     if (!!config.__message) {
            //       if (!config.__message.disable) {
            //         affairService.removeEvent(
            //           config.url,
            //           config.method.toLowerCase()
            //         );
            //       }
            //     } else {
            //       affairService.removeEvent(
            //         config.url,
            //         config.method.toLowerCase()
            //       );
            //     }
            //   }
            // }
            resolve(rep.data);
          } else {
            // 请求失败
            // if (
            //   config.method &&
            //   ["DELETE", "PUT", "POST"].includes(
            //     upperCasePlx(config.method) as any
            //   )
            // ) {
            //   affairService.setErrorPool();
            // }
            reject(res?.data);
          }
        }
        resolve(null);
      })
      .catch((err) => {
        if (err.response) {
          const rep = resolveReq<T>(err.response);
          // 请求失败
          // if (
          //   config.method &&
          //   ["DELETE", "PUT", "POST"].includes(
          //     upperCasePlx(config.method) as any
          //   )
          // ) {
          //   affairService.setErrorPool();
          // }
          if (rep.success) {
            resolve(rep.data);
          } else {
            reject(err);
          }
        } else {
          log("网络请求无响应, 请求参数如下:", tarConfig);
          reject(err);
        }
      })
      .finally(() => {
        reject(null);
      });
  });
};

export default reqService;
