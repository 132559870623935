import { FC, useCallback, useEffect, useState } from "react";
import accountService from "@/store/account/service";
import messageService from "@/store/messages";
import CustomTabs from "@/common/form/CustomTabs";
import "./index.less";
import useEventId from "@/hooks/useEventId";
import useStabilization from "@/hooks/useStabilization";
import userAPI from "@/store/apis/user";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
// import socketService from "@/store/socketService/socketServic";
// import resourceService from "@/resource";

const LayoutContainer: FC = () => {
  const token: any = Cookies.get("UAV_BASIC_TOKEN_KEY");
  const [userName, setUserName] = useState<any>("")
  const [isShow, setIsShow] = useState(false)
  const navigate = useNavigate();
  const handleClick = useCallback(navigate, [navigate]);
  const formId = useEventId();
  const logoImage = require("../../../assets/images/logo.png");
  const quitLogin = useCallback(() => {
    messageService.confirm("确定退出登录吗？", () => {
      Cookies.remove("UAV_BASIC_TOKEN_KEY");
      userAPI.logout().subscribe((res: any) => {
        if (res?.code === 1) {
          setUserName('')
          setIsShow(false)
        }
      });
    });
  }, []);
  const goLogin = () => {
    window.open('https://www.zhufly.com/login.html', '_blank');
  }
  useEffect(() => {
    if (token) {
      setIsShow(true)
    } else {
      setIsShow(false)
    }
  }, [token])
  useEffect(() => {
    if (token) {
      userAPI.detail().subscribe((res: any) => {
        if (res?.code === 1) {
          console.log(res, res);
          setUserName(res?.data?.username)
        }
      });
    } else {
    }
  }, [token])
  // 修改密码
  // const revisePassword = () => {
  //   messageService.createEvent(formId)
  // }
  //修改密码提交
  const submit = (data: any) => {
    userAPI.revisePassword(data).subscribe((res: any) => {
      if (res?.code === 1) {
        messageService.createEvent(formId)
        accountService.logout()
      }
    })
  }
  // 防抖函数
  useStabilization(submit, 500)
  return (
    <section className="header-subcontainer">
      <div onClick={() => handleClick('/monitoring')} style={{ cursor: "pointer", width: "36px", height: "44px" }}>
        <img style={{ width: '100%', height: '100%', margin: "0 8px" }} src={logoImage} alt="" />
      </div>
      <CustomTabs
        tabs={
          userName === 'admin' ? [
            { label: "态势监控", key: "/monitoring", bgImg: require("../../../assets/images/moining.png"), activeImg: require("../../../assets/images/moining_active.png") },
            { label: "航线管理", key: "/line-management", bgImg: require("../../../assets/images/route.png"), activeImg: require("../../../assets/images/route_active.png") },
            { label: "任务管理", key: "/task-management", bgImg: require("../../../assets/images/task.png"), activeImg: require("../../../assets/images/task_active.png") },
            { label: "系统管理", key: "/system-management", bgImg: require("../../../assets/images/system.png"), activeImg: require("../../../assets/images/system_active.png") },
          ] : [
            { label: "态势监控", key: "/monitoring", bgImg: require("../../../assets/images/moining.png"), activeImg: require("../../../assets/images/moining_active.png") },
            { label: "航线管理", key: "/line-management", bgImg: require("../../../assets/images/route.png"), activeImg: require("../../../assets/images/route_active.png") },
          ]}
      />
      {/* <Row wrap={false}> */}
      {/* <Col style={{ whiteSpace: "nowrap" }}>
          <h1>无人机航线规划与态势监控系统</h1>
        </Col> */}
      {/* <Col flex={1}>
          <Row wrap={false} justify={"center"}>
            <Col>
              <div className="header-middle">
              </div>
            </Col>
            <Col>
            </Col>
          </Row>
        </Col>
        <Col>
          <div className="header-right-box">
            <Space size={16}> */}
      {/* <div className="header-right-show">
                <Space size={"small"} align="center">
                  <p>
                    <span>⛅</span>
                  </p>
                  <p>
                    <span>20°C</span>
                  </p>
                </Space>
                <p>2022年9月30日</p>
              </div> */}
      {/* <div style={{ display: "flex" }}>
                <p onClick={revisePassword} style={{ marginRight: "20px" }} className="quit-login">
                  修改密码
                </p>
                <p onClick={quitLogin} className="quit-login">
                  退出
                </p>
              </div> */}
      {/* </Space>
          </div>
        </Col>
      </Row> */}
      {/* <FormP
        width={400}
        submitText="确认修改"
        title="修改密码"
        id={formId}
        submitEvent={revisePasswordSubmit}
        disableTitleFix={true}
      >
        <FormItem name="oldPassword" label="原密码" rules={[{ required: true, message: "请输入原密码" }]}>
          <Input.Password placeholder="请输入8-15位字母加数字" />
        </FormItem>
        <FormItem name="newPassword" label="新密码" rules={[{ required: true, message: "请输入原密码" }]}>
          <Input.Password placeholder="请输入8-15位字母加数字" />
        </FormItem>
        <FormItem name="confirmPassword" label="确认密码" rules={[{ required: true, message: "请输入原密码" }]}>
          <Input.Password placeholder="请输入8-15位字母加数字" />
        </FormItem>
      </FormP> */}
      {isShow && token !== '' &&
        <p onClick={quitLogin} className="quit-login">
          退出
        </p>
      }
      {!isShow &&
        <p onClick={goLogin} className="quit-login">
          登录
        </p>}
    </section >
  );
};
export default LayoutContainer;




