import { AxiosRequestConfig } from "axios";
import { BaseAPIInterface } from "./interface";
import { Observable, from } from "rxjs";
import reqService from "@/store/request";

export abstract class BaseAPI implements BaseAPIInterface {
  private async sendRequest(config: AxiosRequestConfig) {
    try {
      return await reqService<any>(config);
    } catch (error) {
      // console.error(error);
    }
  }

  // get请求方法
  protected get<T = any>(
    url: string,
    params?: T,
    config?: AxiosRequestConfig
  ): Observable<Promise<any>> {
    return from(
      this.sendRequest({
        params,
        ...config,
        method: "get",
        url: `${url}`,
        headers: { ...config?.headers },
      })
    );
  }

  // post请求方法
  protected post<T = any>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Observable<Promise<any>> {
    return from(
      this.sendRequest({
        data: data || {},
        ...config,
        method: "post",
        timeout: 300000,
        url: `${url}`,
        headers: { "Content-Type": "application/json", ...config?.headers },
      })
    );
  }

  // delete请求方法
  protected delete<T = any>(
    url: string,
    params?: T,
    config?: AxiosRequestConfig
  ): Observable<Promise<any>> {
    return from(
      this.sendRequest({
        params,
        ...config,
        method: "delete",
        url: `${url}`,
        headers: { ...config?.headers },
      })
    );
  }

  // delete请求方法
  protected delete_Body<T = any>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Observable<Promise<any>> {
    return from(
      this.sendRequest({
        data: data || {},
        ...config,
        method: "delete",
        url: `${url}`,
        headers: { ...config?.headers },
      })
    );
  }

  // put请求方法
  protected put<T = any>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Observable<Promise<any>> {
    return from(
      this.sendRequest({
        data: data || {},
        ...config,
        method: "put",
        url: `${url}`,
        headers: { "Content-Type": "application/json", ...config?.headers },
      })
    );
  }
}
