import { Subject } from "rxjs";

class Tool {
  /** 路由路径 */
  private location$ = new Subject<string>();
  /** log内容 */
  private logData$ = new Subject<any>();
  constructor() {
    // 日志xaxa
    this.logData$.subscribe((data) => {
    });
    
  }

  /**
   * @param url
   */
  public goPage = (url: string) => {
    this.location$.next(url);
  };

  /**
   * 创建日志
   */
  public createDebug =
    (flag: boolean, name: string) =>
    (message: any, ...optionalParams: any[]) => {
      if (flag) {
        let logData = [message, ...optionalParams];
        logData = [`[##${name}模块##日志]:`, ...logData];
        this.logData$.next(logData);
      }
    };
}

/** 工具包 */
const toolService = new Tool();

export default toolService;
