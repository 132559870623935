import React, { useCallback, useEffect, useMemo, useState } from "react";
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./index.less";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
interface Tab {
  key: string;
  label: string;
  bgImg: string;
  activeImg: string;
}
interface TabsProps {
  tabs: Tab[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isShow, setShow] = useState(false);
  const handleClick = useCallback((key: string) => {
    setShow(false);
    navigate(key);
  }, [navigate]);
  const routeList = [
    {
      label: "机场管理",
      key: "/system-management/airport",
      active: true,
    },
    {
      label: "跑道管理",
      key: "/system-management/track",
      active: false,
    },
    {
      label: "飞机管理",
      key: "/system-management/planeMemger",
      active: false,
    },
    {
      label: "电子围栏管理",
      key: "/system-management/electronic-fence",
      active: false,
    },
    {
      label: "禁飞区管理",
      key: "/system-management/forbidden-air-space",
      active: false,
    },
    {
      label: "参数设置",
      key: "/system-management/map-setting",
      active: false,
    },
  ];
  const goPage = useCallback(
    (key: string, index: number) => {
      routeList.map((item) => {
        return (item.active = false);
      });
      routeList[index].active = true;
      navigate(key);
      setShow(false)
    },
    [navigate, routeList]
  );

  // useEffect(() => {
  //   routeList.forEach((item: any) => {
  //     if (item.active) {
  //       navigate(item.key);
  //     }
  //   });
  // }, [navigate, routeList]);

  const systemHandleClick = useCallback(() => {
    if (!pathname.includes("/system-management")) {
      navigate("/system-management")
    }
    if (isShow) {
      setShow(false)
    } else {
      setShow(true)
    }
  }, [isShow, pathname, navigate])

  const openKey = useMemo(
    () => `/${pathname?.split("/").filter((it) => it)[0]}`,
    [pathname]
  );
  return (
    <div className="tabs">
      <ul className="tab-list">
        {tabs.map((tab) => (
          <li key={tab.key} style={{ width: "50px", height: "44px", padding: "0 8px", background: openKey === tab.key ? '#1594FF' : 'none' }}>
            {
              tab.key !== "/system-management" &&
              <Tooltip overlayInnerStyle={{ backgroundColor: "#333333" }} placement="right" title={tab.label}>
                <div
                  style={{ backgroundImage: openKey === tab.key ? `url(${tab.activeImg})` : `url(${tab.bgImg})` }}
                  className={`tab-item ${openKey === tab.key ? "active" : ""}`}
                  onClick={() => handleClick(tab.key)}
                >
                </div>
              </Tooltip>

            }
            {
              tab.key === "/system-management" &&
              <div
                style={{ backgroundImage: openKey === tab.key ? `url(${tab.activeImg})` : `url(${tab.bgImg})` }}
                className={`tab-item ${openKey === tab.key ? "active" : ""}`}
                onClick={systemHandleClick}
              >
                {isShow &&
                  <div className="selectList">
                    <ul className="list">
                      {
                        routeList.map((item, index) => {
                          return (
                            <li key={item.key} onClick={() => goPage(item.key, index)} className="list-item">{item.label}</li>
                          )
                        })
                      }
                    </ul>
                  </div>}
              </div>
            }
          </li>
        ))}
      </ul>
    </div >
  );
};

export default Tabs;
