import React, { FC } from "react";
import Confirm from "./confirm";
import "./index.less";

const GlbModule: FC = () => {
  return (
    <>
      <Confirm />
    </>
  );
};

export default GlbModule;
