import Login from "@/pages/login";
import { Navigate, createBrowserRouter } from "react-router-dom";
import LayoutContainer from "@/common/Layout";
import { routerList } from "./menu";

export const notLoginRoute = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "*",
    element: <Navigate to={"/login"} />,
  },
]);

export const loginRoute = createBrowserRouter([
  {
    path: "/",
    element: <LayoutContainer />,
    children: routerList,
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);
