import { WebSocketSubject, webSocket } from "rxjs/webSocket";
import { BehaviorSubject, Observable } from "rxjs";
import { AircraftMessage } from "./interFace";
import cesiumSeivers from "@/common/AppViewer/seivers";

class SocketService {
  private aircraftListSubject: BehaviorSubject<AircraftMessage[]> =
    new BehaviorSubject<AircraftMessage[]>([]);
  private wsMap: Map<number, WebSocketSubject<AircraftMessage>> = new Map();
  private wsBase: any;
  private wsTimer: any;
  createWebSocket(aircraftNo: number) {
    this.wsBase = "wss://test.zhufly.com"
    // this.wsBase = "ws://uav.tf.com"
    if (!this.wsMap.get(aircraftNo)) {
      const ws = webSocket<AircraftMessage>({
        url: `${this.wsBase}/ws/flight/${aircraftNo}`,
        // url: `/ws/flight/${aircraftNo}`,
        serializer: (msg: AircraftMessage) => JSON.stringify(msg),
        deserializer: (e: MessageEvent) => JSON.parse(e.data) as AircraftMessage,
      });
      ws.subscribe({
        next: (message) => this.handleMessage(message),
      });
      this.wsMap.set(aircraftNo, ws);
    }
  }

  closeWebSocket(aircraftNo: number) {
    const ws = this.wsMap.get(aircraftNo);
    if (ws) {
      ws.complete();
      this.wsMap.delete(aircraftNo);
    }
  }

  private handleMessage(message: AircraftMessage) {
    // 更新飞机列表
    const updatedList = this.updateAircraftList(message);
    this.aircraftListSubject.next(updatedList);

    //  TODO:你可以干别的事
  }

  private handleError(error: Event, aircraftNo: number) {
  }

  private handleComplete(aircraftNo: number) {

  }

  private updateAircraftList(newAircraft: AircraftMessage): AircraftMessage[] {
    const currentList = this.aircraftListSubject.getValue();
    const existingAircraftIndex = currentList.findIndex(
      (ac) => ac.aircraftNo === newAircraft.aircraftNo
    );

    if (existingAircraftIndex !== -1) {
      if (newAircraft.msgType === 2) {
        // 更新现有飞机
        cesiumSeivers.getViewer().subscribe((viwer: any) => {
          if (!viwer.entities.getById(newAircraft.aircraftNo)) {
            cesiumSeivers.createModel(newAircraft.aircraftNo, newAircraft)
          }
        })
        currentList[existingAircraftIndex] = newAircraft;
        cesiumSeivers.updataPosition(newAircraft)
      } else {
        //根据返回的isShow字段显示隐藏飞机模型
        if (!newAircraft.isShow) {
          cesiumSeivers.modelShow(newAircraft.aircraftNo)
        }
      }
    } else {
      // 添加新飞机
      if (newAircraft.msgType === 2) {
        currentList.push(newAircraft);
        cesiumSeivers.createModel(newAircraft.aircraftNo, newAircraft)
      }
    }

    return [...currentList];
  }


  getAircraftList(): Observable<AircraftMessage[]> {
    return this.aircraftListSubject;
  }

  getWebSocket(
    aircraftNo: number
  ): any | undefined {
    return this.wsMap.get(aircraftNo);
  }


  getMessageStream(
    aircraftNo: number
  ): Observable<AircraftMessage> | undefined {
    const ws = this.wsMap.get(aircraftNo);
    return ws ? ws.asObservable() : undefined;
  }

  sendMessage(): void {
    this.wsTimer = setInterval(() => {
      this.wsMap.forEach((item: any, index: number) => {
        const ws = this.wsMap.get(index);
        if (ws) {
          ws.next({ "aircraftNo": index, "msgType": 1 });
        } else {
          console.error(`未找到飞机 ${index}.`);
        }
      })
    }, 10000)
  }
  closeTimer(): void {
    clearInterval(this.wsTimer);
  }
}

const socketService = new SocketService();
export default socketService;