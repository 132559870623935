import md5 from "md5";
import {
  BehaviorSubject,
  Subject,
  debounceTime,
  delay,
  of,
  switchMap,
} from "rxjs";
import { ELogin, IAccountInfo, ITokenStr } from "./interface";
import userAPI from "../apis/user";
// import { myRouter } from "@/router";
const UAV_BASIC_TOKEN_KEY = md5(window.location.host);
/**
 * 登录账户
 */
class Account {
  readonly info$ = new BehaviorSubject<IAccountInfo | null>(null);
  /** token */
  readonly token$: BehaviorSubject<ITokenStr>;
  readonly openModalIdList$ = new BehaviorSubject<number[]>([]);
  readonly login$: BehaviorSubject<ELogin>;
  readonly logout$ = new Subject();
  constructor() {
    const token = localStorage.getItem(UAV_BASIC_TOKEN_KEY) || "";
    this.login$ = new BehaviorSubject<ELogin>(
      !!token ? ELogin.UNSET : ELogin.LOGINOUT
    );

    this.token$ = new BehaviorSubject<ITokenStr>("");
    this.logout$
      .pipe(debounceTime(50))
      .pipe(switchMap(() => of(this.login$.value !== ELogin.LOGINOUT)))
      .subscribe((res) => {
        if (res) {
          this.resetLoginState();
        }
      });
    this.token$.subscribe((token) => {
      if (token) {
        setTimeout(() => {
          this.initBasic(token);
        }, 100);
      } else {
        localStorage.removeItem(UAV_BASIC_TOKEN_KEY);
      }
    });
    // 自动载入缓存中的有效token
    if (token) {
      this.token$.next(token);
    }
  }
  /**
   * 初始化登录信息
   * @step1 验证token有效
   * @step2 token存储
   */
  private initBasic = (token: string) => {
    const config: any = {};
    config.headers = {};
    config.headers["Authorization"] = token;
    userAPI.detail(config).subscribe((res: any) => {
      if (res?.code === 1) {
        const info = res.data;
        if (info) {
          this.info$.next(info);
          this.login$.next(ELogin.LOGINSU);
          localStorage.setItem(UAV_BASIC_TOKEN_KEY, token);
        }
      } else {
        this.logout();
      }
    });
  };

  setToken(token: string) {
    this.token$.next(token);
  }
  /**
   * 退出登录
   * 清空并重置
   */
  readonly logout = () => {
    const subs = of("logout")
      .pipe(delay(30))
      .subscribe(() => {
        localStorage.removeItem(UAV_BASIC_TOKEN_KEY);
        document.cookie = `name=UAV_BASIC_TOKEN_KEY; expires=${new Date(0)}; path=/; domain=.zhufly.com`;
        this.resetLoginState();
        subs.unsubscribe();
      });
  };

  readonly resetLoginState = () => {
    this.info$.next(null);
    this.token$.next("");
    // setTimeout(() => {
    //   this.login$.next(ELogin.LOGINOUT);
    // }, 500);
  };
}

const accountService = new Account();
export default accountService;
