import { AxiosRequestConfig } from "axios";
import { BaseAPI } from "./base/baseApi";
import { ILoginParams, RevisePasswordParams } from "./interface";

export class UserAPI extends BaseAPI {
  /**登录 */
  login(data: ILoginParams) {
    return this.post("/login", data);
  }
  detail(config?: AxiosRequestConfig) {
    return this.get("/user", {}, config);
  }
  revisePassword(data: RevisePasswordParams) {
    return this.post("/change/pwd", data)
  }
  logout(config?: AxiosRequestConfig) {
    return this.post("/logout", {}, config);
  }
}

const userAPI = new UserAPI();
export default userAPI;
