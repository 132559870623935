import { Button, Form, Input } from "antd";
import { FC, useCallback, useState } from "react";
import "./index.less";
import useSubmitEvent from "@/hooks/useSubmitEvent";
import { useForm } from "antd/es/form/Form";
import { ILoginParams } from "@/store/apis/interface";
import userAPI from "@/store/apis/user";
import accountService from "@/store/account/service";
import { useNavigate } from "react-router-dom";
import bg_url from "@/assets/login/login-bg.png";
import IconFont from "@/common/IconFont";

const Login: FC = () => {
  const [form] = useForm<ILoginParams>();
  const navigate = useNavigate();
  const [loginRules, setLoginRules] = useState<any>();
  // useEffect(() => {
  //   userAPI
  //     .login({ username: "admin", password: "12345678a" })
  //     .subscribe((res: any) => {
  //       if (res?.code === 1 && res?.token) {
  //         accountService.setToken(res?.token);
  //         navigate("/monitoring");
  //       }
  //     });
  // }, [navigate]);
  const submit = useCallback(() => {
    const data = form.getFieldsValue();
    if (data.username && data.password) {
      userAPI.login(data).subscribe((res: any) => {
        if (res?.code === 1 && res?.token) {
          accountService.setToken(res?.token);
          navigate("/monitoring");
          setLoginRules(false);
        }
      });
    }
  }, [form, navigate]);
  const submitEvent = useSubmitEvent(submit);
  // 输入框样式;
  const inpStyle = { border: "1px solid rgb(21, 148, 255)" };
  return (
    <section
      className="login-container"
      style={{ backgroundImage: `url(${bg_url})` }}
    >
      <section className="login-header">
        <div className="header">
          <h1>无人机航线规划与态势监控系统</h1>
        </div>
      </section>
      <div className="login-from">
        <Form layout="vertical" form={form} style={{ width: 300 }}>
          <Form.Item<ILoginParams>
            rules={[
              {
                required: loginRules,
                message: "用户名不能为空",
              },
            ]}
            name="username"
          >
            <Input
              id="userNameId"
              prefix={<IconFont type="icon-yonghu" />}
              style={inpStyle}
              placeholder="请输入用户名"
              onChange={() => setLoginRules(true)}
            />
          </Form.Item>
          <Form.Item<ILoginParams>
            rules={[
              {
                required: loginRules,
                message: "密码不能为空",
              },
            ]}
            name="password"
            style={{ marginBottom: "0px" }}
          >
            <Input
              id="passWordId"
              style={inpStyle}
              placeholder="请输入密码"
              prefix={<IconFont type="icon-mima" />}
              type="password"
              onChange={() => setLoginRules(true)}
            />
          </Form.Item>
          <Form.Item>
            <Button
              onClick={submitEvent}
              style={{
                width: "100%",
                marginTop: " 12%",
                background: "rgba(21, 148, 255, 0.2)",
                border: "1px solid rgba(21, 148, 255, 0.6)",
                color: "rgb(21, 148, 255)",
              }}
              type="primary"
            >
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </section>
  );
};
export default Login;
