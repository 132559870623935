import lazyLoad from "@/common/LazyLoad/lazyLoad";
import { Outlet } from "react-router-dom";
import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
export const routerList: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={"/monitoring"} />,
  },
  {
    path: "monitoring",
    element: lazyLoad(lazy(() => import("@/pages/monitoring"))),
  },
  {
    path: "line-management",
    element: lazyLoad(lazy(() => import("@/pages/lineManagement"))),
  },
  {
    path: "task-management",
    element: lazyLoad(lazy(() => import("@/pages/taskManagement"))),
  },
  {
    path: "system-management",
    element: <Outlet />,
    children: [
      {
        path: "airport",
        element: lazyLoad(
          lazy(() => import("@/pages/systemManagement/airport"))
        ),
      },
      {
        path: "track",
        element: lazyLoad(lazy(() => import("@/pages/systemManagement/track"))),
      },
      {
        path: "electronic-fence",
        element: lazyLoad(
          lazy(() => import("@/pages/systemManagement/electronicFence"))
        ),
      },
      {
        path: "forbidden-air-space",
        element: lazyLoad(
          lazy(() => import("@/pages/systemManagement/forbiddenAirSpace"))
        ),
      },
      {
        path: "map-setting",
        element: lazyLoad(
          lazy(() => import("@/pages/systemManagement/mapSetting"))
        ),
      },
      {
        path: "planeMemger",
        element: lazyLoad(
          lazy(() => import("@/pages/systemManagement/planeMemger"))
        ),
      },
    ],
  },
];
