import { Outlet } from "react-router-dom";
import { FC } from "react";
import "./index.less";
import HeaderContainer from "./header";
import { Layout } from "antd";
import AppViewer from "../AppViewer";
const { Header, Content } = Layout;
// CesiumComp
const LayoutContainer: FC = () => {
  // const location = useLocation();

  // const sysPathname = useMemo(() => {
  //   const currentPath = location.pathname;
  //   return currentPath.startsWith("/system-management");
  // }, [location]);
  return (
    <Layout className="container-lauyout">
      <Header className="uav-gl-header-container">
        <HeaderContainer />
      </Header>
      <Content style={{ display: "flex" }} className="uav-gl-content-container">
        <Layout style={{ flex: 1 }}>
          {/* {sysPathname && (
            <Layout.Sider style={{ width: 327, color: "white" }}>
              <SystemContent />
            </Layout.Sider>
          )} */}
          <Outlet></Outlet>
        </Layout>
      </Content>
      <AppViewer />
    </Layout>
  );
};
export default LayoutContainer;
