import { notification } from "antd";
import { AxiosResponse } from "axios";
import accountService from "../account/service";
import tool from "./tool";
import { IResponseBody } from "@/common/interface";
import { upperCasePlx } from "@/common/utils";
import IconFont from "@/common/IconFont";
import messageService from "../messages";

enum StatusCode {
  Unauthorized = 401,
  Success = 1,
  Failure = 0,
  SpecialSuccess1 = 5001,
  SpecialSuccess2 = 4001,
}

let flag = false;

const log = tool.createDebug(true, "网络请求解析模块");

function resolveReq<T>(rep: AxiosResponse<IResponseBody<T>>) {
  const res = {
    data: null as any,
    success: false,
  };

  if (rep.status !== 200) {
    log("接口请求失败");
    if (rep.status === 401) {
      messageService.confirm(
        `为了确保数据安全，请您登录账户后进行操作。`,
        () => {
          window.open('https://www.zhufly.com/#/login', '_blank');
        },
        {
          // 配置项
          title: "登录提示",
        }
      );
    } else if (rep.status === 400 && rep.status < 500 && rep?.data?.msg) {
      notification.warning({
        icon: <IconFont type="icon-jinggao" />,
        message: rep.data.msg || "参数错误",
      });
    } else if (rep.status === 500) {
      log(rep?.data?.msg)
    }
    return res;
  }

  switch (rep.data.code) {
    case StatusCode.Unauthorized:
      if (!flag) {
        // notification.error({
        //   message: "登录已过期",
        // });
      }
      flag = true;
      setTimeout(() => {
        flag = false;
        accountService.logout();
      }, 2000);
      break;
    case StatusCode.Success:
    case StatusCode.SpecialSuccess1:
    case StatusCode.SpecialSuccess2:
      res.success = true;
      // notification.success({
      //   message: rep.data.msg,
      // });
      if (
        rep.data.msg &&
        rep.config.method &&
        ["DELETE", "PUT", "POST"].includes(
          upperCasePlx(rep.config.method) as any
        )
      ) {
        notification.success({
          icon: <IconFont type="icon-chenggong" />,
          message: rep.data.msg,
          duration: 1.5,
        });
      }
      res.data = rep.data;
      break;
    case StatusCode.Failure:
      notification.error({
        message: rep.data.msg || "操作失败",
      });
      break;
    default:
      res.data = rep
      break;
  }

  if (!res.success) {
    log("请求异常，返回值如下\n", rep.data);
  }

  return res;
}

export default resolveReq;
