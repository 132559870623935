import React, { useRef, useEffect, useCallback, useState } from "react";
import "./index.less"
import {
  Viewer,
  Ion,
  Cartesian3,
  Math as CesiumMath,
  CesiumTerrainProvider,
  WebMapTileServiceImageryProvider,
  GeographicTilingScheme,
  WebMapServiceImageryProvider,
  UrlTemplateImageryProvider,
  Ellipsoid,
  Cartesian2,
  SceneMode,
} from "cesium";
import { CesiumAccessToken } from "./config";
import useUserInfo from "@/hooks/useUserInfo";
import "cesium/Build/Cesium/Widgets/widgets.css";
import cesiumSeivers from "./seivers";
import systemAPI from "@/store/apis/systemManage";
import IconFont from "../IconFont";
import socketService from "@/store/socketService/socketServic";
import { BehaviorSubject } from "rxjs";
import resourceService from "@/resource";
Ion.defaultAccessToken = CesiumAccessToken;
export const queryAlermFlight$ = new BehaviorSubject<{
  alermFlightDistance: any;
} | null>({ alermFlightDistance: '' });

const CesiumComp = () => {
  const [mode, setMode] = useState<any>("icon-a-3D-copy");
  const [noFlyIcon, setNoFlyIcon] = useState<any>("icon-jinfei1-copy");
  const [electronicIcon, setElectronicIcon] = useState<any>("icon-dianziweilan");
  const viewerRef = useRef<any>(null);
  const [flyCode, setFlyCode] = useState<any>(false)
  const userInfo = useUserInfo();
  const [location, setLocation] = useState<any>()
  const queryAlermFlightDistance = (data: any) => {
    queryAlermFlight$.next({ alermFlightDistance: data });
  }
  const init = useCallback(async () => {
    viewerRef.current = new Viewer("cesiumContainer", {
      shouldAnimate: true,
      infoBox: false,
      timeline: true,
      animation: true,
      geocoder: false,
      homeButton: false,
      navigationHelpButton: false,
      sceneModePicker: false,
      baseLayerPicker: false,
      creditContainer: document.createElement("div"),
      fullscreenButton: false,
      selectionIndicator: false,
      orderIndependentTranslucency: true,
      shadows: true,
      imageryProvider: new WebMapServiceImageryProvider({
        url: "",
        layers: ""
      }),
      sceneMode: SceneMode.SCENE3D
      // 高版本代码
      // baseLayer:false,
    });
    viewerRef.current.scene.moon.show = false;
    viewerRef.current.scene.fog.enabled = false;
    viewerRef.current.scene.sun.show = false;
    viewerRef.current.scene.skyBox.show = false;
    viewerRef.current.resolutionScale = 0.9; // 默认值为 1.0；
    viewerRef.current.scene.globe.showGridLines = false;
    cesiumSeivers.setViewer(viewerRef.current)
    cesiumSeivers.controlIsShow(false)
    viewerRef.current.scene.screenSpaceCameraController.enableCollisionDetection = true
    viewerRef.current.scene.globe.translucency.enabled = false;
    // 设置允许的最小和最大倍速
    // const MIN_MULTIPLIER = 0.5;
    // const MAX_MULTIPLIER = 128.0;
    // 创建一个私有变量存储实际的multiplier值
    // let _multiplier = viewerRef.current.clock.multiplier;
    // 创建一个getter和setter，用于控制multiplier值并确保其在范围内
    // Object.defineProperty(viewerRef.current.clock, 'multiplier', {
    //   get: function () {
    //     return _multiplier;
    //   },
    //   set: function (newValue) {
    //     _multiplier = Math.min(Math.max(newValue, MIN_MULTIPLIER), MAX_MULTIPLIER);
    //     viewerRef.current.clock.onTick.addEventListener(() => {
    //       viewerRef.current.clock.multiplier = _multiplier;
    //     })
    //     // 实际应用中可能还需要更新其他与时间相关的逻辑
    //   }
    // });
    systemAPI.querySystemDisposition().subscribe((res: any) => {
      if (res && res.code) {
        setLocation({ lng: res.data.centerLng, lat: res.data.centerLat })
        queryAlermFlightDistance(res.data.alermFlightDistance)
        setLocation({ lng: res.data.centerLng, lat: res.data.centerLat })
        //1.111.0版本写法
        // const terrain = new Terrain(CesiumTerrainProvider.fromUrl(`${res.data.terrainServer}`))
        // viewerRef.current.scene.setTerrain(terrain)
        // 1.101.0版本写法
        if (res.data.terrainServer) {
          viewerRef.current.terrainProvider = new CesiumTerrainProvider({
            url: `${res.data.terrainServer}`,
            requestVertexNormals: false,
            requestWaterMask: false
          })
        }
        cesiumSeivers.setSwitchMapServer(res.data.switchMapServer)
        if (res.data.switchMapServer === "online") {
          // 在线地图初始化方法
          // loadTdt(res.data.tdtToken, res.data.mapServer, res.data.annoUrl)
          const viewer = viewerRef.current;
          if (res.data.tdtToken) {
            //天地图影像
            const TDT_IMG_C = `${res.data.mapServer}${res.data.tdtToken}`;
            //中文标注
            const TDT_CIA_C = `${res.data.annoUrl}${res.data.tdtToken}`;
            //中文注记服务
            viewer.imageryLayers.addImageryProvider(new WebMapTileServiceImageryProvider({
              url: TDT_IMG_C,
              layer: "imgLayer",
              style: "default",
              format: "tiles",
              tileMatrixSetID: "c",
              subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
              tilingScheme: new GeographicTilingScheme(),
              tileMatrixLabels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"],
              minimumLevel: 0,
              maximumLevel: 18,
            }));
            //调用影响中文注记服务
            viewer.imageryLayers.addImageryProvider(new WebMapTileServiceImageryProvider({
              url: TDT_CIA_C,
              layer: "annoLayer",
              style: "default",
              format: "tiles",
              tileMatrixSetID: "c",
              subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
              tilingScheme: new GeographicTilingScheme(),
              tileMatrixLabels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"],
              minimumLevel: 0,
              maximumLevel: 18,
            }));
            setFlyCode(true)
          } else {
            loadMap(res.data.mapServer, res.data.annoUrl)
          }
        } else if (res.data.switchMapServer === "offline") {
          // 离线地图初始化方法
          loadOfflineMaps(res.data.mapServer, res.data.annoUrl)
        }
      }
    })
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    cesiumSeivers.initCreateSocket()
    setTimeout(() => {
      socketService.sendMessage()
    }, 10000)

    const socketTimet = setInterval(() => {
      cesiumSeivers.initCreateSocket()
    }, 10000)

    resourceService.setItem("socketTimet", socketTimet);
  }, [])

  //加载离线地图
  const loadOfflineMaps = (url: any, annoUrl: string) => {
    const viewer = viewerRef.current;
    let imgLayer = new UrlTemplateImageryProvider({
      url: url,
      minimumLevel: 3,
      maximumLevel: 18,
    });
    viewer.imageryLayers.addImageryProvider(imgLayer);
    //标注服务配置
    let annoLayer = new UrlTemplateImageryProvider({
      url: annoUrl,
      minimumLevel: 3,
      maximumLevel: 18
    });
    viewer.imageryLayers.addImageryProvider(annoLayer);
    setFlyCode(true)
  }

  // 地图配置方法
  const loadMap = (imgUrl: string, annoUrl: string) => {
    const viewer = viewerRef.current;
    //影像服务配置
    let imgLayer = null;
    if (imgUrl.includes("map.gtimg.com")) {
      imgLayer = new UrlTemplateImageryProvider({
        url: imgUrl,
        customTags:
        {
          sx: function (imageryProvider: any, x: any, y: any, level: any) { return x >> 4; },
          sy: function (imageryProvider: any, x: any, y: any, level: any) { return ((1 << level) - y) >> 4 }
        },
        minimumLevel: 3,
        maximumLevel: 18
      });
    } else {
      imgLayer = new UrlTemplateImageryProvider({
        url: imgUrl,
        minimumLevel: 3,
        maximumLevel: 18
      });
    }

    viewer.imageryLayers.addImageryProvider(imgLayer);

    //标注服务配置
    let annoLayer = new UrlTemplateImageryProvider({
      url: annoUrl,
      minimumLevel: 3,
      maximumLevel: 18
    });
    viewer.imageryLayers.addImageryProvider(annoLayer);
    setFlyCode(true)
  }

  const RestoreDueNorth = () => {
    const viewer = viewerRef.current;
    if (viewer) {
      const result = viewer.camera.pickEllipsoid(
        new Cartesian2(viewer.canvas.clientWidth / 2, viewer.canvas.clientHeight / 2,),
      );
      const curPosition = Ellipsoid.WGS84.cartesianToCartographic(result);
      const lon = (curPosition.longitude * 180) / Math.PI;//相机中心店经度
      const lat = (curPosition.latitude * 180) / Math.PI;//相机中心店纬度
      const destination = Cartesian3.fromDegrees(lon, lat, viewer.camera.positionCartographic.height);
      viewer?.camera.flyTo({
        destination: destination,
        orientation: {
          heading: 0.0,
          pitch: -CesiumMath.PI_OVER_TWO,
          roll: 0.0,
        },
        duration: 0,
        // maximumHeight: 20000,
        viewFrom: {
          Cartesian: [20.0, 0.0, -10.0],
        },
        complete: () => {
        },
      });
    }
  }

  useEffect(() => {
    const viewer = viewerRef.current;
    if (viewer && location && flyCode) {
      if (cesiumSeivers.SwitchMapServer === "offline") {
        const Local = cesiumSeivers.wgs84togcj02(location.lng * 1, location.lat * 1);
        location.lng = Local[0];
        location.lat = Local[1];
      }
      const destination = Cartesian3.fromDegrees(location.lng * 1, location.lat * 1, 10000);
      viewer?.camera.flyTo({
        destination: destination,
        orientation: {
          heading: 0.0,
          pitch: -CesiumMath.PI_OVER_TWO,
          roll: 0.0,
        },
        duration: 0,
        // maximumHeight: 20000,
        viewFrom: {
          Cartesian: [20.0, 0.0, -10.0],
        },
        complete: () => {
        },
      });
      cesiumSeivers.showGeoJson()
    }
  }, [flyCode, userInfo]);
  // useEffect(() => {
  //   const viewer = viewerRef.current;
  //   if (viewer && userInfo?.centerLat && userInfo?.centerLng && flyCode) {
  // if (cesiumSeivers.SwitchMapServer === "offline") {
  //   const Local = cesiumSeivers.wgs84togcj02(userInfo.centerLng * 1, userInfo.centerLat * 1);
  //   userInfo.centerLng = Local[0];
  //   userInfo.centerLat = Local[1];
  // }
  //     const destination = Cartesian3.fromDegrees(userInfo.centerLng * 1, userInfo.centerLat * 1, 10000);
  //     viewer?.camera.flyTo({
  //       destination: destination,
  //       orientation: {
  //         heading: 0.0,
  //         pitch: -CesiumMath.PI_OVER_TWO,
  //         roll: 0.0,
  //       },
  //       duration: 0,
  //       // maximumHeight: 20000,
  //       viewFrom: {
  //         Cartesian: [20.0, 0.0, -10.0],
  //       },
  //       complete: () => {
  //       },
  //     });
  //     cesiumSeivers.showGeoJson()
  //   }
  // }, [flyCode, userInfo]);

  const mapHandOff = () => {
    const viewer = viewerRef.current;
    if (viewer && viewer.scene.mode === 3) {
      setMode("icon-a-2d-copy")
      viewer.scene.mode = 2;
    } else {
      setMode("icon-a-3D-copy")
      viewer.scene.mode = 3;
    }
  }

  const noFly = () => {
    if (noFlyIcon === "icon-jinfei1-copy") {
      setNoFlyIcon("icon-jinfei");
      cesiumSeivers.controlElectronic(0, true)
    } else {
      setNoFlyIcon("icon-jinfei1-copy");
      cesiumSeivers.controlElectronic(0, false)
    }


  }

  const electronic = () => {
    if (electronicIcon === "icon-dianziweilan") {
      setElectronicIcon("icon-dianziweilan-copy");
      cesiumSeivers.controlNoFlyZone(0, true)
    } else {
      setElectronicIcon("icon-dianziweilan");
      cesiumSeivers.controlNoFlyZone(0, false)
    }

  }

  return (
    <React.Fragment>
      <div
        id="cesiumContainer"
      >
        <div className="buttonList">
          <IconFont onClick={RestoreDueNorth} style={{ fontSize: "30px" }} type="icon-huifuzhengbeifangxiang_0"></IconFont>
          <IconFont onClick={electronic} style={{ fontSize: "30px" }} type={electronicIcon}></IconFont>
          <IconFont onClick={noFly} style={{ fontSize: "30px" }} type={noFlyIcon}></IconFont>
          <IconFont onClick={mapHandOff} style={{ fontSize: "30px" }} type={mode}></IconFont>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CesiumComp;

