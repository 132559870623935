import { ConfigProvider } from "antd";
import { FC } from "react";
import ProjectRouter from "./router";
import GlbModule from "./common/confirm";
import zhCN from "antd/locale/zh_CN";
import theme from "@/config/theme.json";
import "@/style/global.less";
import { StyleProvider } from '@ant-design/cssinjs';

const App: FC = () => {

  return (
    <StyleProvider hashPriority="high">
      <ConfigProvider theme={theme} locale={zhCN}>
        <ProjectRouter />
        <GlbModule />
      </ConfigProvider>
    </StyleProvider>
  );
};

export default App;
