// /**token */
export type ITokenStr = string;

/**用户详情 */
export interface IAccountInfo {
  centerLat:  number;
  centerLng:  number;
  id: number;
  loginTime: string;
  mapServer: string;
  status: number;
  username: string;
}

export interface IUserType {}
/**登录状态 */
export enum ELogin {
  /**初始状态 */
  UNSET = "usr-unSet",
  /** 登录有效 */
  LOGINSU = "usr-loginsu",
  /** 登录失效 */
  LOGINOUT = "usr-loginout",
}
