import useLogin from "@/hooks/useLogin";
import { ELogin } from "@/store/account/interface";
import { FC } from "react";
import { RouterProvider } from "react-router-dom";
// import { notLoginRoute, loginRoute } from "./route";
import { loginRoute } from "./route";

const ProjectRouter: FC = () => {
  const loginStatus = useLogin();
  if (loginStatus === ELogin.LOGINOUT) {
    // return <RouterProvider router={notLoginRoute} />;
  }
  return <RouterProvider router={loginRoute} />;
};

export default ProjectRouter;
