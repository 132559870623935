import { IAccountInfo } from "../store/account/interface";
import accountService from "../store/account/service";
import { useEffect, useState } from "react";

/**
 * 用户当前登录状态监控
 */
const useUserInfo = () => {
  const [state, setState] = useState<IAccountInfo | null>(
    accountService.info$.value
  );
  useEffect(() => {
    const sub = accountService.info$.subscribe((value) => {
      setState(value);
    });
    return () => sub.unsubscribe();
  }, []);
  return state;
};

export default useUserInfo;
