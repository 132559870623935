const useStabilization = (fn: (data: any) => void, delay: number) => {
  let timer: any = null
  return (data: any) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn(data)
    }, delay)
  }
}

export default useStabilization;