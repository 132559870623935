import useSubscribe from "@/hooks/useSubscribe";
import useVisible from "@/hooks/useVisible";
import EInfoType from "@/store/messages/infoType";
import { FC, useState, useCallback } from "react";
import { IConfirmInfo } from "./interface";
import { Button, Col, Modal, Row } from "antd";
// import Draggable from "react-draggable";

const Confirm: FC = () => {
  const [visible, switchVisible] = useVisible();
  const [config, setConfig] = useState<IConfirmInfo>({
    text: "",
    config: {},
    event: () => {},
  });

  const loadConfirm = useCallback(
    (zconfig: IConfirmInfo) => {
      setConfig(zconfig);
      setTimeout(switchVisible, 50);
    },
    [switchVisible]
  );

  const reset = useCallback(() => {
    setConfig({
      text: "",
      event: () => {},
    });
  }, []);

  useSubscribe(EInfoType.confirm_normal, loadConfirm);

  return (
    <Modal
      title={
        <p style={{ textAlign: "center", marginTop: 5 }}>
          {config?.config?.title || "提醒"}
        </p>
      }
      width={config?.config?.width || 266}
      open={visible}
      style={{ minHeight: 200 }}
      className="glb-moadl-confirm"
      onCancel={switchVisible}
      maskClosable={false}
      zIndex={config?.config?.zIndex || 9999}
      destroyOnClose={true}
      afterClose={reset}
      footer={false}
    >
      <div className="comp-global-dialog-confirm">
        <div style={{ minHeight: 80, marginBottom: 12, marginTop: 10 }}>
          {config.text}
        </div>
        <Row className="no-drag" justify={"space-evenly"}>
          <Col>
            <Button
              onClick={() => {
                config.event();
                switchVisible();
              }}
              style={{ width: 100 }}
              size="small"
              type="primary"
            >
              确定
            </Button>
          </Col>
          <Col>
            <Button
              style={{
                width: 100,
                background: "rgba(0, 140, 227, 0.4)",
                border: "none",
              }}
              size="small"
              onClick={switchVisible}
            >
              取消
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default Confirm;
