
import IconFont from "@/common/IconFont";
import droneAPI from "@/store/apis/droneManage";
import systemAPI from "@/store/apis/systemManage";
import socketService from "@/store/socketService/socketServic";
import { notification } from "antd";
import * as Cesium from "cesium";
import {
  Cartesian3,
  Viewer,
  defined,
  Math as CesiumMath,
  // Color,
  // PolylineGlowMaterialProperty,
  JulianDate,
  Color,
  PolylineGlowMaterialProperty,
} from "cesium";
import { BehaviorSubject, Observable } from "rxjs";

class CesiumSeivers {
  private viewerSubject$: BehaviorSubject<Viewer | null> =
    new BehaviorSubject<Viewer | null>(null);
  private Viewer: any;
  private nowDroneInfo: any;
  private pointDraged: any = null;
  private leftDownFlag: any = false;
  private ElectronicFencing: any = [];
  private NoFlyZone: any = [];
  private pointList: any[] = []
  position: any = null
  private PI = 3.1415926535897932384626;
  private a = 6378245.0;
  private ee = 0.00669342162296594323;
  SwitchMapServer = '';
  readonly moveEndPosition$ = new BehaviorSubject<{
    position?: any;
    index?: number;
    code?: any;
  } | null>({ position: null, index: 0, code: false });
  setMoveEndPosition(data?: any, ind?: any, code?: boolean) {
    this.moveEndPosition$.next({ position: data, index: ind, code: code });
  }
  readonly leftClickPosition$ = new BehaviorSubject<{
    position: any[];
  } | null>({ position: [] });
  leftClickPosition(data: any) {
    this.leftClickPosition$.next({ position: data });
  }
  readonly currentAirConfig$ = new BehaviorSubject<{
    show: boolean;
  } | null>({ show: false });
  readonly currentInfoConfig$ = new BehaviorSubject<{
    show: boolean;
  } | null>({ show: false });
  readonly socketDataConfig$ = new BehaviorSubject<{} | null>(null);
  readonly airLineArr$ = new BehaviorSubject<Array<any>>([]);
  readonly Deduction$ = new BehaviorSubject<any>(null);
  setDeduction(data: any) {
    this.Deduction$.next(data);
  }
  readonly socketInfo$ = new BehaviorSubject<{
    infoData: any;
    isPayBack: any;
  } | null>({ infoData: null, isPayBack: 1 });
  setSocketInfo(data: any, code: any) {
    this.socketInfo$.next({ infoData: data, isPayBack: code });
  }
  setAirLineArr(data: any[]) {
    this.airLineArr$.next(data);
  }
  getAirLineArr() {
    this.airLineArr$.asObservable();
  }
  showAirConfig() {
    this.currentAirConfig$.next({ show: true });
  }
  hideAirConfig() {
    this.currentAirConfig$.next({ show: false });
  }

  showInfoConfig() {
    this.currentInfoConfig$.next({ show: true });
  }
  hideInfoConfig() {
    this.currentInfoConfig$.next({ show: false });
  }

  getSocketConfig() {
    this.socketDataConfig$.asObservable();
  }

  setSocketConfig(data: any) {
    this.socketDataConfig$.next(data);
  }

  infoBox: any;
  getViewer(): Observable<Viewer | null> {
    return this.viewerSubject$.asObservable();
  }

  setViewer(viewer: Viewer): void {
    this.Viewer = viewer;
    this.viewerSubject$.next(viewer);
  }

  destroyViewer(): void {
    const currentViewer = this.viewerSubject$.value;
    if (currentViewer) {
      currentViewer.destroy();
      this.viewerSubject$.next(null);
    }
  }

  getPointList(): any {
    return this.pointList;
  }

  setPointList(data: any): any {
    this.pointList = data
  }

  color16 = () => {//十六进制颜色随机
    const colors: string[] = ["#FF6A3A", "#00FFFF", "#FFD700", "#FF0000", "#FFFF00", "#00FF00", "#FF00FF"]
    return colors[Math.floor(Math.random() * 7)];
  }

  //创建实体
  createModel = (
    no: number,
    data: any
  ) => {
    if (this.SwitchMapServer === "offline") {
      const Local = this.wgs84togcj02(data.lng * 1, data.lat * 1);
      data.lng = Local[0];
      data.lat = Local[1];
    }
    this.position = [{ id: no, position: Cartesian3.fromDegrees(data.lng * 1, data.lat * 1, data.height * 1), lineArr: [Cartesian3.fromDegrees(data.lng * 1, data.lat * 1, data.height * 1)] }]
    this.Viewer.entities.add({
      id: no,
      scale: 500,
      name: `飞机编号：${no}`,
      position: new Cesium.CallbackProperty(() => {
        return this.position[this.position.findIndex((item: any) => item.id === no)]?.position;
      }, false),
      model: {
        // uri: '/model/Cesium_Air.glb',
        uri: '/model/feiji.glb',
        minimumPixelSize: 100, //最小的模型像素  
      },
    });
    this.Viewer.entities.add({
      id: `L${no}`,
      polyline: {
        show: true,
        positions: new Cesium.CallbackProperty(() => {
          return this.position[this.position.findIndex((item: any) => item.id === no)]?.lineArr;
        }, false),
        width: 2,
        arcType: Cesium.ArcType.RHUMB,
        material: new Cesium.PolylineDashMaterialProperty({
          color: Color.fromCssColorString(this.color16()),
          dashLength: 20, //短划线长度
        }),
      },
    })
  };

  setSwitchMapServer = (str: any) => {
    this.SwitchMapServer = str
  }
  //更新尾迹坐标
  updataPosition = (data: any) => {
    if (this.SwitchMapServer === "offline") {
      const Local = this.wgs84togcj02(data.lng * 1, data.lat * 1);
      data.lng = Local[0];
      data.lat = Local[1];
    }
    if (this.position.findIndex((item: any) => item.id === data.aircraftNo) !== -1) {
      this.position[this.position.findIndex((item: any) => item.id === data.aircraftNo)].position = Cartesian3.fromDegrees(data.lng, data.lat, data.height - 10)
      if (this.position[this.position.findIndex((item: any) => item.id === data.aircraftNo)].lineArr.length > 3000) {
        this.position[this.position.findIndex((item: any) => item.id === data.aircraftNo)].lineArr.shift()
      }
      this.position[this.position.findIndex((item: any) => item.id === data.aircraftNo)].lineArr.push(Cartesian3.fromDegrees(data.lng, data.lat, data.height - 10))
      if (this.Viewer.entities.getById(data.aircraftNo)) {
        this.Viewer.entities.getById(data.aircraftNo).orientation = this.setModelDirection(
          data.lng,
          data.lat,
          data.height,
          data.yaw,//航向角
          data.pitch,//俯仰角
          data.roll//滚转角
        );
      }
    } else {
      this.position.push({
        id: data.aircraftNo,
        position: Cartesian3.fromDegrees(data.lng, data.lat, data.height - 10),
        lineArr: [
          Cartesian3.fromDegrees(data.lng, data.lat, data.height - 10)
        ]
      })
    }
  }

  // 实体编辑
  editModel = (
    id: any,
    type: string,
    _isShow?: boolean
  ) => {
    switch (type) {
      case "tracked":
        if (this.Viewer.entities.getById(id) && this.Viewer.entities.getById(`L${id}`)) {
          this.Viewer.entities.getById(id).show = true;
          this.Viewer.entities.getById(`L${id}`).show = true;
          const item = this.position[this.position.findIndex((item: any) => item.id === id)]?.position
          const cartographic = this.Viewer.scene.globe.ellipsoid.cartesianToCartographic(item);
          const longitude = CesiumMath.toDegrees(cartographic.longitude);
          const latitude = CesiumMath.toDegrees(cartographic.latitude);
          const destination = Cartesian3.fromDegrees(longitude, latitude, 20000);
          this.Viewer.camera.flyTo({
            destination: destination,
            orientation: {
              heading: 0.0,
              pitch: -CesiumMath.PI_OVER_TWO,
              roll: 0.0,
            },

            duration: 0,
            viewFrom: {
              Cartesian: [20.0, 0.0, -10.0],
            },
          });
        } else {
          notification.warning({
            message: "未接收到遥测数据",
            icon: <IconFont type="icon-jinggao" />,
            duration: 1.5,
          });
        }
        break;
      case "remove":
        this.Viewer.entities.removeById(id);
        break;
      case "isShow":
        if (this.Viewer.entities.getById(id) && this.Viewer.entities.getById(`L${id}`)) {
          this.Viewer.entities.getById(id).show =
            false;
          this.Viewer.entities.getById(`L${id}`).show = false;
        }
        break;
      case "lineIsShow":
        if (this.Viewer.entities.getById(id) && this.Viewer.entities.getById(`L${id}`)) {
          this.Viewer.entities.getById(`L${id}`).show = false;
        }
        break;
    }
  };

  modelShow = (_id: any) => {
    this.Viewer.entities.removeById(_id);
    this.Viewer.entities.removeById(`L${_id}`);
  }

  setModelDirection = (
    longitude: number,
    latitude: number,
    height: number,
    direction: number,
    pitch: number,
    roll: number
  ) => {
    direction = direction - 90;
    if (direction < 0) {
      direction = 360 + direction;
    }
    const center = Cesium.Cartesian3.fromDegrees(longitude, latitude, height);//经纬度高度
    const hpr = new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(direction),
      Cesium.Math.toRadians(pitch), Cesium.Math.toRadians(roll));
    const orientation = Cesium.Transforms.headingPitchRollQuaternion(
      center,
      hpr
    );
    return orientation;
  };

  removeAll = () => {
    this.Viewer.entities.removeAll();
    this.deleteEvent()
  };

  dataSourcesRemoveAll = (name: any) => {
    var allDataSources = this.Viewer.dataSources._dataSources;
    console.log(allDataSources, "allDataSources");
    this.Viewer.dataSources.remove(allDataSources[allDataSources.findIndex((item: any) => item.name === name)]);
    this.deleteEvent()
  };

  // 航线显示居中
  routeMiddle = (lng: number, lat: number, _height?: number) => {
    //经纬度坐标
    const destination = Cartesian3.fromDegrees(lng * 1, lat * 1, 10000);
    this.Viewer.camera.flyTo({
      destination: destination,
      orientation: {
        heading: 0.0,
        pitch: -CesiumMath.PI_OVER_TWO,
        roll: 0.0,
      },
      duration: 0,
      // maximumHeight: 20000,
      viewFrom: {
        Cartesian: [20.0, 0.0, -10.0],
      },
    });
  };

  // 获取海拔高度
  getHeight = (lng: any, lat: any) => {
    const positions = new (Cesium.Cartographic.fromDegrees as any)(lng, lat, 0); //经纬度转为世界坐标
    return parseInt(this.Viewer.scene.globe.getHeight(positions));
  };

  //事件移除
  deleteEvent = () => {
    this.Viewer.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_CLICK
    );

    this.Viewer.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_DOWN
    );
    this.Viewer.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_UP
    );
    this.Viewer.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.MOUSE_MOVE)
  }

  // 事件初始化
  moveInit = () => {
    this.Viewer.screenSpaceEventHandler.setInputAction(
      this.leftDownAction,
      Cesium.ScreenSpaceEventType.LEFT_DOWN
    );
    if (this.Viewer.scene.mode === 3) {
      console.log(1);
      this.Viewer.screenSpaceEventHandler.setInputAction(
        this.mouseMoveAction,
        Cesium.ScreenSpaceEventType.MOUSE_MOVE
      );
    } else {
      console.log(1);
      this.Viewer.screenSpaceEventHandler.setInputAction(
        this.mouseMove,
        Cesium.ScreenSpaceEventType.MOUSE_MOVE
      );
    }
    this.Viewer.screenSpaceEventHandler.setInputAction(
      this.leftUpAction,
      Cesium.ScreenSpaceEventType.LEFT_UP
    );

  };

  //双击结束左键单机事件
  leftClickAction = () => {
    let position: any[] = [];

    this.Viewer.screenSpaceEventHandler.setInputAction((e: any) => {
      const scene = this.Viewer.scene;
      const ellipsoid = scene.globe.ellipsoid;
      const cartesian = this.Viewer.camera.pickEllipsoid(e.position, ellipsoid);
      if (cartesian) {
        const cartographic = ellipsoid.cartesianToCartographic(cartesian);
        let lon =
          Math.round(Cesium.Math.toDegrees(cartographic.longitude) * 10000000) /
          10000000;
        let lat =
          Math.round(Cesium.Math.toDegrees(cartographic.latitude) * 10000000) /
          10000000;
        //地理高度
        if (this.SwitchMapServer === "offline") {
          let Local = cesiumSeivers.gcj02towgs84(lon, lat);
          lon = Local[0];
          lat = Local[1];
        }
        const height = this.getHeight(lon, lat);
        if (position.length > 1) {
          if (lon !== position[position.length - 1].lng && lat !== position[position.length - 1].lat) {
            const entitity = this.Viewer.entities.add({
              name: "db_point",
              position: Cartesian3.fromDegrees(lon, lat, height),
              point: {
                pixelSize: 6,//点的大小
                color: Cesium.Color.fromCssColorString("rgba(209,1,33,1)"),//点的颜色
                outlineWidth: 3,//边框宽度
                outlineColor: Cesium.Color.fromCssColorString("rgba(209,1,33,.3)"),//边框颜色
                material: new PolylineGlowMaterialProperty({
                  glowPower: 0.2,
                  color: Color.fromCssColorString("rgba(209,1,33,1)"),
                }),
              },
            })
            this.pointList.push(entitity)
            position.push({ lng: lon, lat: lat, height: height });
          }
        } else {
          const entititys = this.Viewer.entities.add({
            name: "db_point",
            position: Cartesian3.fromDegrees(lon, lat, height),
            point: {
              pixelSize: 6,//点的大小
              color: Cesium.Color.fromCssColorString("rgba(209,1,33,1)"),//点的颜色
              outlineWidth: 3,//边框宽度
              outlineColor: Cesium.Color.fromCssColorString("rgba(209,1,33,.3)"),//边框颜色
              material: new PolylineGlowMaterialProperty({
                glowPower: 0.6,
                color: Color.fromCssColorString("rgba(209,1,33,1)"),
              }),
            },
          })
          this.pointList.push(entititys)
          position.push({ lng: lon, lat: lat, height: height });
        }
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    this.Viewer.screenSpaceEventHandler.setInputAction((e: any) => {
      this.Viewer.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.LEFT_CLICK
      );
      this.leftClickPosition(position);
      this.pointList.forEach((item: any) => {
        if (item) {
          this.Viewer.entities.remove(item)
        }
      })
    }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
  };

  //快捷左键单击
  leftClickQk = () => {
    this.leftClickPosition({});
  };

  drawPolygon = () => {
    let position: any[] = [];
    this.Viewer.screenSpaceEventHandler.setInputAction((e: any) => {
      const scene = this.Viewer.scene;
      const ellipsoid = scene.globe.ellipsoid;
      const cartesian = this.Viewer.camera.pickEllipsoid(e.position, ellipsoid);
      if (cartesian) {
        const cartographic = ellipsoid.cartesianToCartographic(cartesian);
        const lon =
          Math.round(Cesium.Math.toDegrees(cartographic.longitude) * 10000000) /
          10000000;
        const lat =
          Math.round(Cesium.Math.toDegrees(cartographic.latitude) * 10000000) /
          10000000;
        //地理高度
        const height = this.getHeight(lon, lat);
        this.Viewer.entities.add({
          position: Cartesian3.fromDegrees(lon, lat, height),
          point: {
            pixelSize: 6,//点的大小
            color: Cesium.Color.fromCssColorString("rgba(209,1,33,1)"),//点的颜色
            outlineWidth: 3,//边框宽度
            outlineColor: Cesium.Color.fromCssColorString("rgba(209,1,33,.3)"),//边框颜色
            material: new PolylineGlowMaterialProperty({
              glowPower: 0.6,
              color: Color.fromCssColorString("rgba(209,1,33,1)"),
            }),
          },
          polyline: {
            show: true,
            positions: Cartesian3.fromDegrees(lon, lat, height),
            width: 4,
            arcType: Cesium.ArcType.RHUMB,
            material: new Cesium.PolylineDashMaterialProperty({
              color: Color.fromCssColorString(this.color16()),
              dashLength: 0 //短划线长度
            })
          },
        })
        position.push({ lng: lon, lat: lat, height: height });
      }
      this.leftClickPosition(position)
      this.Viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
  }

  // 左键按下事件
  leftDownAction = (e: any) => {
    const pick = this.Viewer.scene.pick(e.position);
    if (defined(pick)) {
      this.pointDraged = this.Viewer.scene.pick(e.position); //选取当前的entity
      this.leftDownFlag = true;
      if (this.pointDraged) {
        this.Viewer.scene.screenSpaceCameraController.enableRotate = false; //锁定相机
      }
    }
  };

  // 左键抬起事件
  leftUpAction = (_e: any) => {
    this.leftDownFlag = false;
    this.pointDraged = null;
    this.Viewer.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_DOWN
    );
    this.Viewer.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_UP
    );
    this.Viewer.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.MOUSE_MOVE
    );
    this.setMoveEndPosition(
      this.nowDroneInfo?.position,
      this.nowDroneInfo?.id * 1,
      true
    );
    this.Viewer.scene.screenSpaceCameraController.enableRotate = true; //解锁相机
  };

  // 鼠标拖动事件
  mouseMoveAction = (e: { endPosition: any }) => {
    if (this.leftDownFlag === true && this.pointDraged != null) {
      const ray = this.Viewer.camera.getPickRay(e.endPosition);
      const cartesian = this.Viewer.scene.globe.pick(ray, this.Viewer.scene);
      this.pointDraged.id.position = new Cesium.CallbackProperty(() => {
        this.nowDroneInfo = {
          position: cartesian,
          id: this.pointDraged.id._id.replace(/[^\d]/g, " "),
        };
        return cartesian;
      }, false);
    }
  };

  mouseMove = (e: any) => {
    if (this.leftDownFlag === true && this.pointDraged) {
      var cartesian = this.Viewer.scene.camera.pickEllipsoid(e.endPosition, this.Viewer.scene.globe.ellipsoid);
      if (cartesian) {
        this.pointDraged.id.position = new Cesium.CallbackProperty(() => {
          this.nowDroneInfo = {
            position: cartesian,
            id: this.pointDraged.id._id.replace(/[^\d]/g, " "),
          };
          return cartesian;
        }, false);
      }
    }
  }


  // 自行结束左键点击
  leftClick = () => {
    let position: any[] = [];
    this.Viewer.screenSpaceEventHandler.setInputAction((e: any) => {
      const scene = this.Viewer.scene;
      const ellipsoid = scene.globe.ellipsoid;
      const cartesian = this.Viewer.camera.pickEllipsoid(e.position, ellipsoid);
      if (cartesian) {
        const cartographic = ellipsoid.cartesianToCartographic(cartesian);
        let lon =
          Math.round(Cesium.Math.toDegrees(cartographic.longitude) * 10000000) /
          10000000;
        let lat =
          Math.round(Cesium.Math.toDegrees(cartographic.latitude) * 10000000) /
          10000000;
        //地理高度
        if (this.SwitchMapServer === "offline") {
          let Local = cesiumSeivers.gcj02towgs84(lon, lat);
          lon = Local[0];
          lat = Local[1];
        }
        const height = this.getHeight(lon, lat);
        position.push({ lng: lon, lat: lat, height: height });
      }
      this.leftClickPosition(position)
      this.Viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
  }

  // 自行结束左键点击
  leftClickChoose = (isOver: boolean) => {
    if (!isOver) {
      let position: any[] = [];
      this.Viewer.screenSpaceEventHandler.setInputAction((e: any) => {
        const scene = this.Viewer.scene;
        const ellipsoid = scene.globe.ellipsoid;
        const cartesian = this.Viewer.camera.pickEllipsoid(e.position, ellipsoid);
        if (cartesian) {
          const cartographic = ellipsoid.cartesianToCartographic(cartesian);
          const lon =
            Math.round(Cesium.Math.toDegrees(cartographic.longitude) * 10000000) /
            10000000;
          const lat =
            Math.round(Cesium.Math.toDegrees(cartographic.latitude) * 10000000) /
            10000000;
          //地理高度
          const height = this.getHeight(lon, lat);
          this.Viewer.entities.add({
            position: Cartesian3.fromDegrees(lon, lat, height),
            point: {
              pixelSize: 6,//点的大小
              color: Cesium.Color.fromCssColorString("rgba(209,1,33,1)"),//点的颜色
              outlineWidth: 3,//边框宽度
              outlineColor: Cesium.Color.fromCssColorString("rgba(209,1,33,.3)"),//边框颜色
              material: new PolylineGlowMaterialProperty({
                glowPower: 0.6,
                color: Color.fromCssColorString("rgba(209,1,33,1)"),
              }),
            },
          })
          position.push({ lng: lon, lat: lat, height: height });
        }
        this.leftClickPosition(position)
        this.Viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    } else {
      this.removeAll()
    }
  }

  controlElectronic = (id?: any, status?: any) => {
    // 先过滤出所有符合要求的元素
    // let filteredArray = array.filter((item: any) => item._name === `noFly${id}`);
    // 然后取过滤后的数组的最后一个元素
    // let lastMatchedElement = filteredArray.pop();
    if (id && this.ElectronicFencing.findIndex((item: any) => item._name === `dzwl${id}`) !== -1) {
      let item = this.ElectronicFencing.filter((item: any) => item._name === `dzwl${id}`);
      item = item.pop();
      item.show = !item.show;
      if (item.show) {
        let position = item?.entities?.values[0]?.polygon?.hierarchy?._value?.positions[0]
        let cartographicPosition = Cesium.Cartographic.fromCartesian(position);
        // cartographicPosition现在包含经度、纬度和高度
        let longitude = Cesium.Math.toDegrees(cartographicPosition.longitude);
        let latitude = Cesium.Math.toDegrees(cartographicPosition.latitude);
        const destination = Cartesian3.fromDegrees(longitude, latitude, 20000);
        this.Viewer.camera.flyTo({
          destination: destination,
          orientation: {
            heading: 0.0,
            pitch: -CesiumMath.PI_OVER_TWO,
            roll: 0.0,
          },
          duration: 0,
          viewFrom: {
            Cartesian: [20.0, 0.0, -10.0],
          },
        });
      }
      return;
    }
    this.ElectronicFencing.forEach((item: any) => {
      item.show = status;
    });
  };

  controlNoFlyZone = (id?: any, status?: any) => {
    if (id && this.NoFlyZone.findIndex((item: any) => item._name === `noFly${id}`) !== -1) {
      let item = this.NoFlyZone.filter((item: any) => item._name === `noFly${id}`)
      item = item.pop();
      item.show = !item.show;
      if (item.show) {
        let position = item?.entities?.values[0]?.polygon?.hierarchy?._value?.positions[0]
        let cartographicPosition = Cesium.Cartographic.fromCartesian(position);
        // cartographicPosition现在包含经度、纬度和高度
        let longitude = Cesium.Math.toDegrees(cartographicPosition.longitude);
        let latitude = Cesium.Math.toDegrees(cartographicPosition.latitude);
        const destination = Cartesian3.fromDegrees(longitude, latitude, 20000);
        this.Viewer.camera.flyTo({
          destination: destination,
          orientation: {
            heading: 0.0,
            pitch: -CesiumMath.PI_OVER_TWO,
            roll: 0.0,
          },
          duration: 0,
          viewFrom: {
            Cartesian: [20.0, 0.0, -10.0],
          },
        });
      }
      return;
    }
    this.NoFlyZone.forEach((item: any) => {
      item.show = status;
    });
  };

  //显示GeoJson数据
  showGeoJson = () => {
    if (this.NoFlyZone.length) {
      this.NoFlyZone.forEach((item: any) => {
        this.Viewer.dataSources.remove(item);
      });
    }
    if (this.ElectronicFencing.length) {
      this.ElectronicFencing.forEach((item: any) => {
        this.Viewer.dataSources.remove(item);
      });
    }

    systemAPI
      .searchForbiddenAirSpace({ pageNum: 1, pageSize: 10 })
      .subscribe((res: any) => {
        //电子围栏
        if (res?.data) {
          res.data.forEach((item: any) => {
            if (item.areaJson) {
              let dataInfo = JSON.parse(item.areaJson)
              if (cesiumSeivers.SwitchMapServer === "offline") {
                dataInfo.geometries[0].coordinates[0].forEach((item: any) => {
                  const local = cesiumSeivers.wgs84togcj02(item[0], item[1]);
                  item[0] = local[0];
                  item[1] = local[1];
                })
              }
              this.Viewer.dataSources
                .add(
                  Cesium.GeoJsonDataSource.load(dataInfo, {
                    stroke: Cesium.Color.YELLOW,
                    strokeWidth: 10.5,
                    fill: Cesium.Color.RED.withAlpha(0.3),
                  })
                )
                .then((data: any) => {
                  data.show = this.ElectronicFencing.length ? this.ElectronicFencing[0].show : false;
                  data.name = `dzwl${item.id}`
                  this.ElectronicFencing.push(data);
                });
            }
          });
        }
      });

    // 禁飞区
    systemAPI.searchElectronicFence({}).subscribe((res: any) => {
      if (res?.data) {
        res.data.forEach((item: any) => {
          if (item.areaJson) {
            let dataInfo = JSON.parse(item.areaJson)
            if (cesiumSeivers.SwitchMapServer === "offline") {
              dataInfo.geometries[0].coordinates[0].forEach((item: any) => {
                const locals = cesiumSeivers.wgs84togcj02(item[0], item[1]);
                item[0] = locals[0];
                item[1] = locals[1];
              })
            }
            this.Viewer.dataSources
              .add(
                Cesium.GeoJsonDataSource.load(dataInfo, {
                  stroke: Cesium.Color.YELLOW,
                  strokeWidth: 10.5,
                  fill: Cesium.Color.YELLOW.withAlpha(0),
                })
              )
              .then((data: any) => {
                data.show = this.NoFlyZone.length ? this.NoFlyZone[0].show : false;
                data.name = `noFly${item.id}`
                this.NoFlyZone.push(data);
              });
          }
        });
      }
    });
  };

  //初始化socket链接
  initCreateSocket = () => {
    droneAPI.queryDrone({ pageNum: 1, pageSize: 255 }).subscribe((res: any) => {
      if (res)
        res.data.forEach((item: any) => {
          socketService.createWebSocket(item.aircraftNo)
        });
    })
  }

  playBack = (id: any, socketData: any, positionData: any, start: any, end: any, linePosition: any) => {
    // this.Viewer.clock.clockRange = ClockRange.LOOP_STOP;
    this.Viewer.dataSources._dataSources.forEach((item: any) => {
      if (item._name === "CZML Path") {
        this.Viewer.dataSources.remove(item)
      }
    })
    if (socketData.length) {
      this.Viewer.entities.add({
        id: `_${id}`,
        polyline: {
          show: true,
          positions: new Cesium.CallbackProperty((time, result) => {
            // const isPaused = this.Viewer.clock.shouldAnimate;
            let nowData = new Date(time.toString()).getTime()
            for (let i = 0; i < socketData.length; i++) {
              if ((Math.abs(socketData[i].timeStamp - nowData)) < 200) {
                this.setSocketInfo(socketData[i], true);
                break;
              }
            }
          }, false),
        }
      });
    }

    const czml = [
      {
        id: `document`,
        name: "CZML Path",
        version: "1.0",
        clock: {
          interval: `${start}/${end}`,
          currentTime: start,
          multiplier: 5,
        },
      },
      {
        id: `path${id}`,
        name: "path with GPS flight data",
        availability: `${start}/${end}`,
        // 飞机模型
        model: {
          // gltf: '/model/Cesium_Air.glb',
          gltf: '/model/feiji.glb',
          minimumPixelSize: 100, //最小的模型像素  
          maximumScale: 200 // 最大缩放比例（相对于原始模型尺寸）
        },
        position: {
          epoch: start,
          cartographicDegrees: positionData
        },
        path: {
          show: true,
          resolution: 1,
          leadTime: 0,
          trailTime: 0
        },
      },
      {
        id: `line${id}`,
        name: "path with GPS flight data",
        availability: `${start}/${end}`,
        path: {
          material: {
            solidColor: {
              color: {
                interval: `${start}/${end}`,
                rgba: [0, 255, 255, 255],
              },
            },
            leadTime: 1,
          },
          width: 2,
        },
        position: {
          epoch: start,
          cartographicDegrees: linePosition
        },
      }
    ];
    const destination = Cartesian3.fromDegrees(positionData[1] * 1, positionData[2] * 1, 20000);
    this.Viewer.camera.flyTo({
      destination: destination,
      orientation: {
        heading: 0.0,
        pitch: -CesiumMath.PI_OVER_TWO,
        roll: 0.0,
      },
      duration: 0,
      // maximumHeight: 20000,
      viewFrom: {
        Cartesian: [20.0, 0.0, -10.0],
      },
    });
    let s: any = null
    this.Viewer.dataSources.add(Cesium.CzmlDataSource.load(czml)).then((ds: any) => {
      s = ds.entities.getById(`path${id}`);
      s.orientation = new Cesium.VelocityOrientationProperty(s.position);
    });

  }

  formMateTime = (spTime: any) => {
    const times = new Date().getTime()
    console.log(new Date(), 'spTime', spTime);
    let t = new Date(times)
    let endT = new Date(times + spTime * 1000)
    let t1 = JulianDate.fromDate(endT)
    let julianT = JulianDate.fromDate(t);
    return { start: julianT.toString(), end: t1.toString(), startJ: julianT };
  }

  formatTime = (seconds: any) => {
    let hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    let minutes = Math.floor(seconds / 60);
    seconds %= 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  FlightSimulation = (id: any, positionData: any, spTime: any, distance: any, speedList: any) => {
    console.log(spTime, "总秒数", distance, "总航程", speedList, "每段数据");
    const times = this.formMateTime(spTime)
    this.Viewer.dataSources._dataSources.forEach((item: any) => {
      if (item._name === "CZML Path") {
        this.Viewer.dataSources.remove(item)
      }
    })
    this.toggleAnimation()
    this.dataSourcesRemoveAll("flightPathAll")
    if (this.Viewer.entities.getById(`nowFlightPath_${id}`)) {
      this.Viewer.entities.remove(this.Viewer.entities.getById(`nowFlightPath_${id}`))
    }
    this.Viewer.entities.add({
      id: `nowFlightPath_${id}`,
      polyline: {
        show: true,
        positions: new Cesium.CallbackProperty((time, result) => {
          console.log(time, times.startJ);
          let seconds = Cesium.JulianDate.secondsDifference(time, times.startJ);//飞过的秒数
          console.log(seconds, "秒数");
          let index = speedList.findIndex((item: any) => item.t > seconds);//已经飞到了第几个航点
          let residue = 0;
          let residueTime = this.formatTime(Math.round(spTime - seconds));
          if (index > 0) {
            const nowT = seconds - speedList[index - 1].t;//此时这一段飞过的秒数
            const nowTrip = (nowT / 3600) * speedList[index].s//此时这一段飞过距离
            const previous = speedList[index - 1].range;
            console.log(nowTrip, "此时这一段飞过的距离");
            console.log(previous, "飞过的距离");
            residue = distance - (nowTrip + previous)
            console.log(residue, "剩余总航程");

          } else if (index === 0) {
            residue = distance - ((seconds / 3600) * speedList[0].s)
          }
          this.setDeduction({ voyage: (Math.floor(residue * 100) / 100) < 0 ? 0.00 : (Math.floor(residue * 100) / 100), time: residueTime })
        }, false),
      }
    });
    const czml = [
      {
        id: "document",
        name: "CZML Path",
        version: "1.0",
        clock: {
          interval: `${times.start}/${times.end}`,
          currentTime: times.start,
          multiplier: 5,
        },
      },
      {
        id: "path",
        name: "path with GPS flight data",
        description:
          "<p>Hang gliding flight log data from Daniel H. Friedman.<br>Icon created by Larisa Skosyrska from the Noun Project</p>",
        availability: `${times.start}/${times.end}`,
        // 飞机模型
        model: {
          // gltf: '/model/Cesium_Air.glb',
          gltf: '/model/feiji.glb',
          minimumPixelSize: 100, //最小的模型像素  
        },
        position: {
          epoch: times.start,
          cartographicDegrees: positionData
        },
      }
    ];
    const destination = Cartesian3.fromDegrees(positionData[1] * 1, positionData[2] * 1, 20000);
    this.Viewer.camera.flyTo({
      destination: destination,
      orientation: {
        heading: 0.0,
        pitch: -CesiumMath.PI_OVER_TWO,
        roll: 0.0,
      },
      duration: 0,
      // maximumHeight: 20000,
      viewFrom: {
        Cartesian: [20.0, 0.0, -10.0],
      },
    });
    let s: any = null
    this.Viewer.dataSources.add(Cesium.CzmlDataSource.load(czml)).then((ds: any) => {
      s = ds.entities.getById("path");
      s.orientation = new Cesium.VelocityOrientationProperty(s.position);
    });
  }
  // 动态切换自动播放状态
  toggleAnimation() {
    this.Viewer.clock.shouldAnimate = !this.Viewer.clock.shouldAnimate;
  }

  controlIsShow = (isShow: boolean) => {
    if (isShow) {
      this.Viewer.animation.container.style.visibility = "visible";
      this.Viewer.timeline.container.style.visibility = "visible";
      const utc = JulianDate.fromDate(new Date("2019/10/04 12:00:00"));
      this.Viewer.clock.currentTime = JulianDate.addHours(utc, 8, new JulianDate());
      this.Viewer.animation.viewModel.dateFormatter = this.dateFormatter;
      this.Viewer.animation.viewModel.timeFormatter = this.timeFormatter;
      this.Viewer.timeline.makeLabel = this.dateTimeFormatter;
    } else {
      this.Viewer.animation.container.style.visibility = "hidden";
      this.Viewer.timeline.container.style.visibility = "hidden";
    }
  }

  // 日期时间格式化
  dateTimeFormatter = (time: any, viewModel: any) => {
    const newDate = JulianDate.toDate(time);
    let hour = newDate.getHours().toString().padStart(2, '0');
    let minute = newDate.getMinutes().toString().padStart(2, '0');
    let second = newDate.getSeconds().toString().padStart(2, '0');
    return newDate.toLocaleDateString() + " " + hour + ":" + minute + ":" + second;
  }

  // 日期格式化
  dateFormatter = (time: any, viewModel: any) => {
    let t = JulianDate.toDate(time);
    return t.toLocaleDateString()
  }

  // 时间格式化
  timeFormatter = (time: any, viewModel: any) => {
    const newDate = JulianDate.toDate(time);
    let hour = newDate.getHours().toString().padStart(2, '0');
    let minute = newDate.getMinutes().toString().padStart(2, '0');
    let second = newDate.getSeconds().toString().padStart(2, '0');
    return hour + ":" + minute + ":" + second;
  }

  //绘制多边形
  // 创建一个PolygonGraphics对象
  drawpolygon = (id: any, positions: any) => {
    this.Viewer.entities.add({
      id: id,
      name: "Red polygon on surface",
      polygon: {
        hierarchy: Cartesian3.fromDegreesArray(positions),
        material: Color.RED.withAlpha(0.5),
      }
    })
  }

  /**
  * WGS84转GCj02
  * @param lng
  * @param lat
  * @returns {*[]}
  */
  wgs84togcj02(lng: number, lat: number) {
    lng = Number(lng);
    lat = Number(lat);
    if (this.out_of_china(lng, lat)) {
      return [lng, lat]
    } else {
      var dlat = this.transformlat(lng - 105.0, lat - 35.0);
      var dlng = this.transformlng(lng - 105.0, lat - 35.0);
      var radlat = lat / 180.0 * this.PI;
      var magic = Math.sin(radlat);
      magic = 1 - this.ee * magic * magic;
      var sqrtmagic = Math.sqrt(magic);
      dlat = (dlat * 180.0) / ((this.a * (1 - this.ee)) / (magic * sqrtmagic) * this.PI);
      dlng = (dlng * 180.0) / (this.a / sqrtmagic * Math.cos(radlat) * this.PI);
      var mglat = lat + dlat;
      var mglng = lng + dlng;
      mglat = Number.parseFloat(mglat.toFixed(7))
      mglng = Number.parseFloat(mglng.toFixed(7))
      return [mglng, mglat]
    }
  }
  /**
   * GCJ02 转换为 WGS84
   * @param lng
   * @param lat
   * @returns {*[]}
   */
  gcj02towgs84(lng: any, lat: any) {
    lng = Number(lng);
    lat = Number(lat);
    if (this.out_of_china(lng, lat)) {
      return [lng, lat]
    } else {
      var dlat = this.transformlat(lng - 105.0, lat - 35.0);
      var dlng = this.transformlng(lng - 105.0, lat - 35.0);
      var radlat = lat / 180.0 * this.PI;
      var magic = Math.sin(radlat);
      magic = 1 - this.ee * magic * magic;
      var sqrtmagic = Math.sqrt(magic);
      dlat = (dlat * 180.0) / ((this.a * (1 - this.ee)) / (magic * sqrtmagic) * this.PI);
      dlng = (dlng * 180.0) / (this.a / sqrtmagic * Math.cos(radlat) * this.PI);
      var mglat = lat + dlat;
      var mglng = lng + dlng;
      mglat = Number.parseFloat((lat * 2 - mglat).toFixed(7))
      mglng = Number.parseFloat((lng * 2 - mglng).toFixed(7))
      return [mglng, mglat]
    }
  }

  transformlat(lng: any, lat: any) {
    var ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng));
    ret += (20.0 * Math.sin(6.0 * lng * this.PI) + 20.0 * Math.sin(2.0 * lng * this.PI)) * 2.0 / 3.0;
    ret += (20.0 * Math.sin(lat * this.PI) + 40.0 * Math.sin(lat / 3.0 * this.PI)) * 2.0 / 3.0;
    ret += (160.0 * Math.sin(lat / 12.0 * this.PI) + 320 * Math.sin(lat * this.PI / 30.0)) * 2.0 / 3.0;
    return ret
  }

  transformlng(lng: any, lat: any) {
    var ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng));
    ret += (20.0 * Math.sin(6.0 * lng * this.PI) + 20.0 * Math.sin(2.0 * lng * this.PI)) * 2.0 / 3.0;
    ret += (20.0 * Math.sin(lng * this.PI) + 40.0 * Math.sin(lng / 3.0 * this.PI)) * 2.0 / 3.0;
    ret += (150.0 * Math.sin(lng / 12.0 * this.PI) + 300.0 * Math.sin(lng / 30.0 * this.PI)) * 2.0 / 3.0;
    return ret
  }
  /**
   * 判断是否在国内，不在国内则不做偏移
   * @param lng
   * @param lat
   * @returns {boolean}
   */
  out_of_china(lng: any, lat: any) {
    return (lng < 72.004 || lng > 137.8347) || ((lat < 0.8293 || lat > 55.8271) || false);
  }
}

const cesiumSeivers = new CesiumSeivers();

export default cesiumSeivers;
