import { IMapT } from "../common/interface";
import reqServicePlx from "../store/request/reqServicePlx";
import { AxiosRequestConfig } from "axios";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
/**
 * 资源读取
 * - dev环境不读取本地
 */
class Resource {
  readonly CKS = "AUTHORITY_UAV_KEY";
  private cache$ = new BehaviorSubject<IMapT<any>>({});
  // 当前正在处理的缓存
  private stack: IMapT<number> = {};
  writeLocale = () => {
    // 缓存写到本地
    setTimeout(() => {
      const str = JSON.stringify(this.cache$.value);
      localStorage.setItem(this.CKS, str);
    }, 50);
  };

  /**
   * 查询缓存
   */
  getItem = (key: string) => {
    return this.cache$.value[key] !== undefined ? this.cache$.value[key] : null;
  };

  /**
   * 缓存
   */
  setItem = (key: string, value: any) => {
    const newValue = {
      ...this.cache$.value,
      [key]: value,
    };
    this.cache$.next(newValue);
    // 缓存写到本地
    this.writeLocale();
  };

  /**
   * 静态资源添加订阅
   * 支持请求缓存和普通属性缓存
   */
  pipe = (value: AxiosRequestConfig | string, loader?: any) => {
    if (typeof value === "string") {
      return this.cache$.pipe(map((_value: IMapT<any>) => _value[value]));
    } else {
      if (!value.url) {
        throw Error("需要缓存的数据，没有url");
      }

      const key = value.url;
      if (this.stack[key] !== 1) {
        if (!!value) {
          // 加入处理站
          this.stack[key] = 1;
          reqServicePlx<any>(value).then((res) => {
            if (res.isSuccess) {
              const context = loader ? loader(res.result) : res.result;
              this.setItem(key, context);
            }
          });
        }
      }
      return this.cache$.pipe(map((value: IMapT<any>) => value[key]));
    }
  };
}

/**
 * 静态资源缓存
 */
const resourceService = new Resource();

export default resourceService;
