import { AxiosRequestConfig } from "axios";
import dayjs from "dayjs";
import { forkJoin, from } from "rxjs";
import {
  ICallback,
  IMap,
  IMapT,
  IMenuLv2,
  ISelectItem,
  ISelectOptionConfig,
  ITagEnum,
} from "./interface";
import reqServicePlx from "@/store/request/reqServicePlx";
import * as turf from "@turf/turf";
// import { IMenu } from "../layout/sider/interface";
export const xorCompare = (a: any, b: any) => {
  const _a = !!a ? 1 : 0;
  const _b = !!b ? 1 : 0;
  return _a ^ _b;
};

/**
 * 移除对象值为空的属性
 * @param a 一定不是空值
 */
export const removeEmptyObject = (a: any) => {
  if (!a) {
    return null;
  }
  if (typeof a !== "object") {
    return a;
  }
  let result: any = null;
  const keys = Object.keys(a);
  if (keys.length < 1) {
    return null;
  }
  keys.forEach((key) => {
    const value = removeEmptyObject(a[key]);
    if (value !== null) {
      if (!result) {
        result = {};
      }
      result[key] = value;
    }
  });
  return result;
};

/**
 * 比对两个对象，数组，简单属性
 * @description 判断a中的所有属性都在b中存在，并且相等
 * true 表示两者属性相等,
 * @param a
 * @param b
 * @param properties
 */
export const compareA_B: <T>(
  a: T,
  b: T,
  properties?: Array<keyof T>
) => boolean = <T>(a: T, b: T, properties?: Array<keyof T>) => {
  if (a === b) {
    return true;
  }
  if (typeof a !== "object") {
    return `${a}` === `${b}`;
  }
  // a, b 有一个为空值
  if (xorCompare(a, b)) {
    const onlyOne = removeEmptyObject(a || b);
    return !onlyOne;
  }
  if (!a && !b) {
    return true;
  }
  if (Array.isArray(a)) {
    if (!Array.isArray(b)) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }
    return a.every((at) => b.some((bt) => compareA_B(at, bt)));
  }
  if (!a || !b) {
    return false;
  }
  const aProperties = Object.keys(a) as Array<keyof T>;
  const bProperties = Object.keys(b) as Array<keyof T>;

  // 保证两者至少都有一个属性
  if (aProperties.length * bProperties.length < 1) {
    return false;
  }
  // 普通对象
  const compareProperties: Array<keyof T> =
    properties ||
    (aProperties.length < bProperties.length ? aProperties : bProperties);
  // eslint-disable-next-line eqeqeq
  return compareProperties.every((key) => compareA_B(a[key], b[key]));
};

// 全角转半角
export const ToCDB = (data: any) => {
  try {
    const _dataStr = JSON.stringify(data);
    let tmp = "";
    for (let i = 0; i < _dataStr.length; i++) {
      if (_dataStr.charCodeAt(i) === 12288) {
        tmp += String.fromCharCode(_dataStr.charCodeAt(i) - 12256);
        continue;
      }
      if (_dataStr.charCodeAt(i) > 65280 && _dataStr.charCodeAt(i) < 65375) {
        tmp += String.fromCharCode(_dataStr.charCodeAt(i) - 65248);
      } else {
        tmp += String.fromCharCode(_dataStr.charCodeAt(i));
      }
    }
    const _data = JSON.parse(tmp);
    return _data;
  } catch (error) {
    console.error("全角转换半角失败", error);
    return data;
  }
};

/**
 * 字符串转大写
 */
export const upperCasePlx = (value: string | number) => {
  return typeof value === "string" ? value.toUpperCase() : value;
};
/**
 * 给菜单附加基本路径
 * @param menus
 * @param baseName
 */
export const compilMenu = (menus: IMenuLv2[]): any[] => {
  return menus.map((menu) => {
    return {
      ...menu,
      routeAddress: `${menu.routeAddress}`,
    };
  });
};

/**
 * -修改对象的特定属性名
 * -反向转换时还原属性名
 * @param data 被转换的值
 * @param map 属性转换表
 * @param reverse 反向转换
 * @description 处理非法属性名
 */
export const propertyReplace = (data: any, map: IMap, reverse = false) => {
  let _data: any = { ...data };
  for (const key in map) {
    if (Object.prototype.hasOwnProperty.call(map, key)) {
      const _origin = reverse ? map[key] : key;
      const _target = reverse ? key : map[key];
      // 如果属性名和目标属性名不相同，需要交换
      if (_origin !== _target) {
        _data[_target] = _data[_origin];
        delete _data[_origin];
      }
    }
  }
  return _data;
};

/**
 * -将几个属性合并成一个新属性(删除原属性)
 * -反向转换时将属性值更新到原属性
 * @param data 原有对象
 * @param map 属性合并
 * @param reverse 反向转换
 * @description 为自定义组件新增属性
 * @notice 合并的属性名不能和原有属性名重合
 */
export const propertyMerge = (
  data: any,
  map: IMapT<string[]>,
  reverse = false
) => {
  let _data: any = { ...data };
  const needRemoveKey: any = {};
  for (const key in map) {
    if (!reverse) {
      const target: any = {};
      map[key].forEach((dataKey) => {
        target[dataKey] = data[dataKey];
        needRemoveKey[dataKey] = 1;
      });
      if (map[key].length > 0) {
        _data[key] = target;
      }
    } else {
      // 将属性值还原
      map[key].forEach((dataKey) => {
        _data[dataKey] = !!_data[key] ? _data[key][dataKey] : undefined;
      });
      if (map[key].length > 0) {
        needRemoveKey[key] = 1;
      }
    }
  }
  // 删除原有属性
  Object.keys(needRemoveKey).forEach((key) => {
    delete _data[key];
  });
  return _data;
};

// 将后台需要的枚举值转换为前端展示值
export const transformTimeZone = (
  data: ISelectItem[] | string[],
  value: string | number
) => {
  const map: any = {};
  data.forEach((option: any) => {
    if (typeof option === "string") {
      map[option] = option;
    } else {
      map[`${option.id}`] = option.name;
    }
  });
  if (!value && value !== 0) {
    return "-";
  }
  if (typeof map[value] === "undefined") {
    console.error("出现未知选项", value);
    return value;
  }
  return map[`${value}`];
};

/**
 * 常用流程
 * 请求接口成功
 * 支持多个接口
 */
export const reqAndRunCallback = <T = any>(
  config: AxiosRequestConfig | AxiosRequestConfig[],
  cb?: ICallback<any>
) => {
  if (Array.isArray(config)) {
    forkJoin(config.map((conf) => reqServicePlx<T>(conf))).subscribe(
      (resList) => {
        if (resList.every((res) => res.isSuccess)) {
          const results = resList.map((res) => res.result);
          cb && cb(results);
        }
      }
    );
  } else {
    from(reqServicePlx<T>(config)).subscribe((res) => {
      if (res.isSuccess) {
        cb && cb(res.result);
      }
    });
  }
};

/**
 * 从下拉选项读取相应的值，并根据select的展示规则输出text
 */
export const queryValueFromList = (
  configs: Array<ISelectItem | string>,
  value: any,
  keyConfig?: ISelectOptionConfig
) => {
  const idKey = keyConfig ? keyConfig.idKey : "id";
  const textKey = keyConfig ? keyConfig.textKey : "name";
  if (value !== "") {
    const it = configs.find((config) => {
      if (typeof config === "string") {
        return config === value;
      }
      if (typeof config === "object") {
        return config[idKey] === value;
      }
      return false;
    });
    if (typeof it === "string") {
      return it.toUpperCase();
    }
    if (typeof it === "object") {
      return it[textKey];
    }
    return null;
  }
  return null;
};

export const reqAnalysis = (res: any) => {
  if (res.hasOwnProperty("data")) {
    return res || null;
  }
  return null;
};

// 对数组进行数据抽象，获取key
export const generateKey = (data: any[], key: string) => {
  try {
    if (data.length > 0) {
      if (data[0] && typeof data[0][key] !== "undefined") {
        throw new Error("数组生成unikey失败");
      }
    }
    return data.map((item, index) => {
      return {
        ...item,
        [key]: index,
      };
    });
  } catch (error) {
    console.error(error);
    return [];
  }
};
//航程计算
export const totalRangeFn = (arr: any[]) => {
  return (
    Math.round(turf.length(turf.lineString(arr), { units: "kilometers" }) * 100) /
    100
  );
};

//按规则算出下一航点经纬度
export const getNextPoint = (start: any, distance: number, bearing: number) => {
  const pt = turf.point(start);
  const bearingPt = turf.destination(pt, distance, bearing, { units: "kilometers" });
  return bearingPt;
};
/**
 * 运行状态
 * @param value 运行结果
 */
export const getStatusProps = (value: -1 | 0 | 1, map: ITagEnum[]) => {
  const result = map.find((item) => item.id === value);
  return result;
};

/**
 * react.reactElement 深度遍历查找指定元素的props
 * @param data React.ReactElement, 实际类型不验证，应为访问的是私有属性
 * @param elType 元素类型
 */
export const findElementPropsInNode = (data: any, elType: string) => {
  if (data.type === elType || (data.type && data.type.name === elType)) {
    return [data.props];
  }
  let list: any[] = [];
  if (Array.isArray(data.props.children)) {
    data.props.children.map((child: any) => {
      const znode = findElementPropsInNode(child, elType);
      list = list.concat(znode);
      return true;
    });
    return list;
  } else {
    return [];
  }
};

export const getPropertyP = (data: any, key?: string) => {
  if (!data || !key) {
    return "";
  }
  // 防止0被过滤
  const value = typeof data[key] === "number" ? data[key] : data[key] || "";
  return value;
};

/**
 *对复杂json对象，按照ASCII进行排序
 * @param itemObj
 * @returns
 */
export function filterAscIISort(itemObj: IMapT<any> | any[]) {
  try {
    if (Array.isArray(itemObj)) {
      return {};
    }
    const keysArr = Object.keys(itemObj).sort();
    let newObj;
    if (Array.isArray(keysArr)) {
      newObj = keysArr.reduce((obj: any, current: string) => {
        if (itemObj.hasOwnProperty(current)) {
          if (
            itemObj[current] &&
            typeof itemObj[current] === "object" &&
            !Array.isArray(itemObj[current])
          ) {
            obj[current] = filterAscIISort(itemObj[current]);
          } else if (Array.isArray(itemObj[current])) {
            obj[current] = itemObj[current].map((arrItem: any) => {
              if (typeof arrItem === "object") {
                return filterAscIISort(arrItem);
              }
              return arrItem;
            });
          } else {
            obj[current] = itemObj[current];
          }
        }
        return obj;
      }, {});
    }
    return newObj;
  } catch (err) {
    console.error(err);
  }
}

// 生成唯一uuid
export function getUuid() {
  const s: string[] = [];
  const hexDigits = "0123456789abcdef";
  for (let i = 0; i < 32; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";
  s[19] = hexDigits.substr((9 & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23];
  const uuid = s.join("");
  return uuid;
}

// 给后台需要的值拼接百分比串
export const splicedPercentageSign = (value: string | number) => {
  if (!value && value !== 0) {
    return "-";
  }
  if (typeof value === "undefined") {
    console.error("出现未知选项", value);
    return value;
  }
  return `${value}%`;
};

export const removeKAndVNumber = (data: any) => {
  if (!Array.isArray(data)) {
    return [];
  }
  const newFormatted: any[] = [];
  Object.values(data).forEach((t) => {
    newFormatted.push([dayjs(t.k).format("YYYY-MM-DD"), Number(t.v)]);
  });
  return newFormatted;
};
/**
 *
 * @param func
 * @param delay
 * @returns 防抖函数
 */
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  delay: number
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  let context: ThisParameterType<T>;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
    context = this;
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func.apply(context, args);
      timeout = null;
    }, delay);
  };
}

export const areArraysSimilar = (con1: string[], con2: string[]): boolean => {
  if (con1.length !== con2.length) {
    return false;
  }
  const sortedArr1 = con1.slice().sort();
  const sortedArr2 = con2.slice().sort();
  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};

export const deepEqual = (obj1: IMapT<any>, obj2: IMapT<any>) => {
  // 首先检查类型是否相同
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // 检查属性数量是否相同
  if (keys1.length !== keys2.length) {
    return false;
  }

  // 逐一比较每个属性
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};
