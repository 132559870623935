import { AxiosRequestConfig } from "axios";
import { BaseAPI } from "./base/baseApi";
import {
  AddAirportParams, QueryAirportParams, DeleteAirportParams, UpdateSystemDispositionParams, AddRunwayParams, DeleteRunwayParams, UpadateRunwayParams, AirportDetailParams, UpdataAirportParams, addElectronicFenceParams, deleteElectronicFenceParams, updateElectronicFenceParams, QueryElectronicFenceParams,
  addForbiddenAirSpaceParams, deleteForbiddenAirSpaceParams, updateForbiddenAirSpaceParams, QueryForbiddenAirSpaceParams, QueryRunwayParams, importRunway
} from "./interface"
const headers = {
  headers: {
    'content-type': 'multipart/form-data',
  }
}
export class SystemAPI extends BaseAPI {
  // 新增机场
  addAirport(data: AddAirportParams) {
    return this.post("/airport", data);
  }
  // 查询机场
  queryAirport(data: QueryAirportParams) {
    return this.get("/airport", data);
  }
  // 删除机场
  deleteAirport(data: DeleteAirportParams) {
    return this.delete("/airport", data);
  }
  //机场详情
  airportDetail(data: AirportDetailParams) {
    return this.get("/airport/detail", data)
  }
  //更新机场
  airportUpdata(data: UpdataAirportParams) {
    return this.put("/airport", data)
  }
  // 更新系统配置
  updateSystemDisposition(data: UpdateSystemDispositionParams) {
    return this.post("/sys/config", data);
  }
  // 查询系统配置
  querySystemDisposition(config?: AxiosRequestConfig) {
    return this.get("/sys/config", config);
  }
  // 查询跑道列表
  searchRunwayList(data: QueryRunwayParams) {
    return this.get("/runway", data)
  }
  // 新增跑道
  addRunway(data: AddRunwayParams) {
    return this.post("/runway", data)
  }
  // 删除跑道
  deleteRunway(data: DeleteRunwayParams) {
    return this.delete("/runway", data)
  }
  // 更新跑道
  updateRunway(data: UpadateRunwayParams) {
    return this.put("/runway", data)
  }


  // 查询电子围栏
  searchElectronicFence(data: QueryElectronicFenceParams) {
    return this.get("/fence", data)
  }
  // 新增电子围栏
  addElectronicFence(data: addElectronicFenceParams) {
    return this.post("/fence", data)
  }
  // 删除电子围栏
  deleteElectronicFence(data: deleteElectronicFenceParams) {
    return this.delete("/fence", data)
  }
  // 更新电子围栏
  updateElectronicFence(data: updateElectronicFenceParams) {
    return this.put("/fence", data)
  }

  // 查询禁飞区
  searchForbiddenAirSpace(data: QueryForbiddenAirSpaceParams) {
    return this.get("/nofly-zone", data)
  }
  // 新增禁飞区
  addForbiddenAirSpace(data: addForbiddenAirSpaceParams) {
    return this.post("/nofly-zone", data)
  }
  // 删除禁飞区
  deleteForbiddenAirSpace(data: deleteForbiddenAirSpaceParams) {
    return this.delete("/nofly-zone", data)
  }
  // 更新禁飞区
  updateForbiddenAirSpace(data: updateForbiddenAirSpaceParams) {
    return this.put("/nofly-zone", data)
  }
  //导入跑道
  importRunway(data: importRunway) {
    return this.post("/runway/import", data, headers)
  }
  //导出跑道
  exportRunway(config?: AxiosRequestConfig) {
    return this.get("/runway/export", config, {
      responseType: "blob",
    })
  }
}
const systemAPI = new SystemAPI();
export default systemAPI;